.columnbox--3 {
  clear: both;
  margin: 15px 0;
  background-color: $color-lightgrey;
  text-align: center;

  &__text {
    padding: 30px;

    p, ul {
      font-size: 16px;
      line-height: 1.2;
      color: $text-color;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,ol {
      padding: 0 0 0 15px;
      text-align: left;
      width: 200px;
      margin: 10px auto;

      li {
        overflow: initial;
        white-space: initial;
        margin-bottom: 5px;
      }
    }

    h2{
      color: $text-color;
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 22px;

      @media (min-width: $screen-md-min) {
        font-size: 24px;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    h3,h4 {
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 5px;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    font-size: 25px;
    margin: 0;
    padding: 30px 0;
  }

  &__price {
    font-size: 30px;
    color: $text-color;
    margin: 30px 0;
    text-align: center;
  }

  .btn-brandcolor {
    width: 100%;
    height: auto;
    padding: 10px 15px;

    &:visited {
      color: white;
    }
  }

  > div {
    border-bottom: 1px solid white;

    &:last-child {
      border-bottom: none;
    }
  }

  @media (min-width: $screen-md-min){
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-align: left;

    &__title {
      margin: 0 30px;
      word-break: break-word;
    }

    &__text {
      ul {
        width: auto;
        margin: 10px 0;
        text-align: left;
      }
    }

    > div {
      width: 33%;
      flex: 1 1 auto;
      align-self: stretch;
      border-left: 1px solid white;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        justify-content: flex-end;

        .columnbox--3__price {
          flex: 1 1 auto;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
        }
      }
    }
  }

}