.result-header {
    border-bottom: 1px solid #c1c1c1;
    margin-top: 50px;
    font-size: 22px;
}

.result-header a {
    color: $text-color;
    display: inline-block;
    padding: 0 0 0 40px;
}

.result-header a.active {
    @include themeColor();
}

.result-header a .count {
    font-size: 14px;
}

.search-result__title {
    margin: 60px 0 30px 0;
}

.search-result {
    margin-top: 40px;
}

.search-result .sr {
    @extend .pagelinks__item;
}

.paginate-search {
    text-align: center;
    margin: 60px 0 100px;

    p {
        position:relative;
        display: inline-block;
    }

    a {
        color: $text-color;
        font-size: 20px;
        padding: 10px;

        &.paginate-search__left, &.paginate-search__right {
            width: 50px;
            height: 50px;
            position: absolute;
            top: -13px;
            color: $text-color;

            svg {
                width: 21px;
                height: 30px;
                fill: currentColor;
            }

            &:hover,&:focus {
                @include themeColor();
            }
        }


        &.paginate-search__left {
            margin-left: -50px;
            svg {
                transform: rotate(180deg);
            }
        }
        &.paginate-search__right {
            left: 100%;
        }

        &.active {
            @include themeColor();
            font-weight:600;
        }
    }
}
