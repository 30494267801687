﻿.union {
    text-align: center;
    margin-bottom: 20px;

    div:nth-child(2n+1) > & {
        clear: left;

        @media (min-width: $screen-md-min) {
            clear: none;
        }
    }

    @media (min-width: $screen-md-min) {
        div:nth-child(3n+1) > & {
            clear: left;
        }
    }

    @media (min-width: $screen-lg-min) {
        div:nth-child(3n+1) > & {
            clear: none;
        }

        div:nth-child(4n+1) > & {
            clear: left;
        }
    }

    a {
        display: block;
    }

    .image {
        display: table;
        width: 100%;

        figure {
            display: table-cell;
            height: 150px;
            vertical-align: middle;

            img {
                max-height: 100%;
                width: auto;
            }
        }
    }

    .name {
        margin-top: 5px;
    }
}