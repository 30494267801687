﻿.banner {
    height: 415px;
    background: url(/Images/top-banner.jpg) repeat-x;
    margin-top: 16px;


    &--self-service {
        min-height: 430px;
        height: auto;
        display: flex;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        //ie-fix
        width:100%;

        .banner__content {
            @extend .container-fluid;

            // This should center the box vertically.
            flex: 1;
            align-self: center;
            padding: 0;
            margin-top: $grid-gutter-width / 2;
            margin-bottom: $grid-gutter-width / 2;

            // This will prevent ugly horizontal scrolling on small screens
            overflow: hidden;
        }
    }
}

.banner-advice {
    background: url(/Images/banner-advice-1.jpg) center center no-repeat;
    height: 415px;

    h1 {
        color: $text-color;
        font-size: 48px;
        letter-spacing: -2px;

        span {
            display: block;
            font-size: 30px;
            line-height: normal;
            letter-spacing: -1px;
            margin-top: 30px;
        }
    }
}

.banner-2 {
    background: url(/Images/banner-fan.jpg) center center no-repeat;
    height: 415px;

    h1 {
        color: $text-color;
        font-size: 48px;
        letter-spacing: -2px;
        margin-top: 144px;
    }
}

.banner-home-2 {
    background: url(/Images/banner-1.jpg) center center no-repeat;
    height: 415px;

    h1 {
        color: $text-color;
        font-size: 48px;
        letter-spacing: -2px;
    }
}

.banner-cookie {
    background: url(/Images/banner-cookie.jpg) center center no-repeat;
    height: 415px;

    h1 {
        color: $text-color;
        font-size: 48px;
        letter-spacing: -2px;
    }
}

.banner-install {
    background: url(/Images/banner-installation.jpg) center center no-repeat;
    height: 415px;

    h1 {
        color: $text-color;
        font-size: 48px;
        letter-spacing: -2px;
    }
}

.banner-store {
    background: url(/Images/banner-store.jpg) center center no-repeat;
    height: 415px;

    h1 {
        color: #fff;
        font-size: 40px;
        letter-spacing: -2px;

        span {
            display: block;
            font-size: 30px;
            line-height: normal;
            letter-spacing: -1px;
            margin-top: 30px;
        }
    }
}

.banner-fiber {
    background: url(/Images/banner-fiber.jpg) center center no-repeat;
    height: 415px;
    overflow: hidden;

    h1 {
        color: $text-color;
        font-size: 48px;
        letter-spacing: -2px;

        span {
            display: block;
            font-size: 30px;
            line-height: normal;
            letter-spacing: -1px;
            margin-top: 30px;
        }
    }
}

.banner-elnet {
    background: url(/Images/banner-elnet.jpg) center center no-repeat;
    height: 415px;

    h1 {
        color: $text-color;
        font-size: 48px;
        letter-spacing: -2px;

        span {
            display: block;
            font-size: 30px;
            line-height: normal;
            letter-spacing: -1px;
            margin-top: 30px;
        }
    }
}

.banner-corporate {
    background: url(/Images/banner-corporate.jpg) center center no-repeat;
    height: 415px;

    h1 {
        color: $text-color;
        font-size: 48px;
        letter-spacing: -2px;

        span {
            display: block;
            font-size: 30px;
            line-height: normal;
            letter-spacing: -1px;
            margin-top: 30px;

            &.seperate-10 {
                display: block;
                margin-top: 20px;
            }
        }
    }
}


.banner-handel {
    background: url(/Images/banner-el-handel.jpg) center center no-repeat;
    height: 415px;

    h1 {
        color: $text-color;
        font-size: 48px;
        letter-spacing: -2px;

        span {
            display: block;
            font-size: 30px;
            line-height: normal;
            letter-spacing: -1px;
            margin-top: 30px;
        }
    }
}

.banner-service {
    background: url(/Images/customer-service.jpg) center center no-repeat #f7f7f7;
    height: 415px;

    h1 {
        color: $text-color;
        font-size: 48px;
        letter-spacing: -2px;

        span {
            display: block;
            font-size: 30px;
            line-height: normal;
            letter-spacing: -1px;
            margin-top: 30px;
        }
    }
}
