.accord-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 300px;
    border-bottom: 1px solid #d4d7d9;

    &.light{
        border-bottom:0;
    }

    //Adding light class to avoid re-inventing the wheel and making it possible to keep the accord-nav if needed
    &.light{
        a{
            background:transparent;
            color:#525e66;
            font-weight:600;

            &:hover,
            &:focus{
                color:#d80b8c;
            }

        }

        & > li{

            padding:0 0 20px 0;

            & > a{
                border-top:0;
                font-size:19px;
                padding:0;
            }

            & > ul{
                margin:15px 0 0 20px;

                ul{
                    margin-top:10px;
                }

                li a{
                    font-size:17px;
                    padding:0;
                }
            }
        }

        .level-1{
            &:last-child{
                margin:0;
            }
        }

        .level-2,
        .level-3{
            margin:0 0 15px 0;

            &:last-child{
                margin:0;
            }
        }

    }

    & > li {
        display: block;

        & > a {
            color: $text-color;
            border-top: 1px solid #d4d7d9;
            display: block;
            padding: 16px 20px;
            background: url(/Images/arrow-right-privat.png) 6px 24px no-repeat;

            &:active,
            &:focus,
            &:hover {
                text-decoration: none;
                outline: 0;
            }

            &.active,&:focus {
                @include themeColor();
            }
        }

        ul {
            list-style: none;
            margin: 0 0 20px 34px;
            padding: 0;

            li {
                margin: 0;
                padding: 0;
                line-height: normal;

                a {
                    color: $text-color;
                    font-size: 14px;
                    line-height: normal;
                    background: url(/Images/arrow-rt-grey.png) left 6px no-repeat;
                    padding-left: 10px;

                    &:active,
                    &:focus,
                    &:hover {
                        text-decoration: none;
                        outline: 0;
                    }

                    &.active {
                        color: $color-brand;
                    }
                }
            }
        }

    }
}

.accord-nav > li > ul{
    margin-top:-14px;
}

.toggle{

    & + .accord-sub-nav{
        display:none;
    }

    &.active + .accord-sub-nav{
        display:block;
    }
}

// MULTI LEVEL ACCORDION BASED ON BOOTSTRAP
.multi-lvl-accordion {
    .panel-heading {
        padding:0;
        h4 {
            button {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 18px 1.5rem;
                @include themeBaggroundColor();
                color: white !important;
                font-size: 17px;
                line-height: 1.2;
                min-height:61px;


                > span {
                    flex: 0 1 auto;
                }

                .icon-accordion {
                    transform: rotate(45deg);
                    transition: transform 0.3s ease-out;
                    svg {
                        width:17px;
                        height:17px;
                        fill:currentColor;
                    }
                }

                &.collapsed {
                    background-color: #EEEEEE;
                    color: $text-color !important;
                    .icon-accordion {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }
    > .panel {
        border-top: 1px solid white !important;
        > .panel-collapse.in {
            padding-bottom: 20px;
        }
        + .panel {
            margin-top: 0;
        }
    }
    .panel .panel {
        .panel-heading {
            h4 {
                button {
                    font-size: 16px;
                    background-color: white;
                    color: $color-brand !important;

                    .theme-yourelectricity & {
                        color: $color-turquise !important;
                    }

                    &.collapsed {
                        background-color: #EEEEEE;
                        color: $text-color !important;
                        border-top: 1px solid white;
                    }
                }
            }
        }
    }

    //panel content
    .panel-body {
        font-size: 17px;
        line-height: 1.4;
        padding: 2rem 1.5rem 0;

        h4 {
            margin: 20px 0 0 0;
        }

        ol,ul {
            padding-left: 16px;
        }
    }

    &.accordion-theme--light {
        background-color:#EEEEEE;
        .panel-heading {
            padding: 0;
            h4 {
                button {
                    background-color: white;
                    color: $color-brand !important;

                    .theme-yourelectricity & {
                        color: $color-turquise !important;
                    }

                    &.collapsed {
                        background-color: white;
                        color: $text-color !important;
                    }
                }
            }
        }
        .panel .panel {
            .panel-heading {
                h4 {
                    button {
                        background-color: #eee;
                        color: $color-brand !important;

                        .theme-yourelectricity & {
                            color: $color-turquise !important;
                        }
                        &.collapsed {
                            background-color: white;
                            color: $text-color !important;
                        }
                    }
                }
            }
        }
    }
}