.cta-banner {
  position: relative;
  clear: both;
  overflow: hidden;

  .img--object-fit-cover {
    height: 200px;

    @media (min-width: $screen-md-min){
      height: 360px;
    }
  }



  @media screen and (max-width:$screen-md) {
    > .container {
      width:100%;
      padding: 0;
      margin-top: -100px;
    }
  }

  .focusEntries__inner {
    display: flex;
    flex-flow: row-reverse wrap-reverse;
    justify-content: flex-end;
    height:100%;
    .focusEntry {
      position: relative;
      width: 100%;
      @include themeBaggroundColor();

      @media screen and (min-width:$screen-md-min) {
        &:after {
          content: "";
          position: absolute;
          left: 100%;
          top: 0;
          width: calc((100vw - 940px) / 2);
          height: 180px;
          background-color: inherit;
        }
      }

      @media screen and (min-width:$screen-lg-min) {
        &:after {
          width: calc((100vw - 1140px) / 2);
        }
      }
      a.focusEntry__link {
        display: flex;
        align-items: center;
        height: 100px;
        padding: 0 15px;
        @media screen and (min-width:$screen-md-min) {
          padding: 0 50px;
        }
        text-decoration: none;
        h2 {
          color: white;
          font-size: 23px;
          font-weight: 600;
          @media screen and (min-width:$screen-md-min) {
            font-size: 35px;
          }
          line-height: 1;
          margin: 0 0 6px;
        }

        p {
          color: white;
          font-size: 16px;
          line-height: 1;
          @media screen and (min-width:$screen-md-min) {
            font-size: 18px;
          }
          margin: 0;

          span.arrow-link--right {
            margin: -7px 0 0 15px;
            position: absolute;
            @media screen and (max-width:$screen-sm) {
              right:15px;
            }
            svg {
              width: 30px;
              height: 30px;
              fill: currentColor;
            }
          }
        }

        &:hover, &:focus {
          h2 {
            text-decoration: underline;
          }
        }
      }

      &:nth-child(1) {
        order:2;
        @media screen and (min-width:$screen-md-min) {
          order:3;
        }
        h2 {
          color: $header-color !important;
        }
        p {
          color: $text-color !important;
        }
        background-color: #F6F6F6;
      }
      &:nth-child(2) {
        order:3;
        @media screen and (min-width:$screen-md-min) {
          order:2;
        }
        h2 {
          color: $header-color !important;
        }
        p {
          color: $text-color !important;
        }
        background-color: rgba(255, 255, 255, .8);
        &:after {
          content: none;
        }
      }
      &:nth-child(3) {
        order:1;
      }
    }
  }
  @media screen and (min-width:$screen-md-min) {
    height:360px;

    > .container {
      margin-top: -360px;
      min-height: 360px;
    }
    .focusEntries__inner {
      justify-content: flex-start;
      height: 360px;
      align-items: flex-start;
      .focusEntry {
        width: 430px;
        height: 180px;
        a.focusEntry__link {
          height: 180px;
        }
      }
    }
  }

  .linkLists_inner {
    .focusEntry {
      position:relative;
      width:100%;
      padding: 15px;
      height: auto;
      @media screen and (min-width:$screen-sm-min) {
        padding: 50px;
      }
      @media screen and (min-width:$screen-md-min) {
        height: 400px;
        width: 430px;
        float:right;
      }

      background-color: #F6F6F6;

      h2{
        color:$header-color;
        font-weight: 600;
        font-size: 23px;
        @media screen and (min-width:$screen-sm-min) {
          font-size: 35px;
        }
        margin: 0 0 8px;
      }
      p {
        color: $text-color;
        margin: 0 0 15px;
        @media screen and (min-width:$screen-sm-min) {
          margin: 0 0 25px;
        }
      }
      a {
        display: flex;
        height: 42px;
        align-items: center;
        color: $text-color;
        justify-content: space-between;
        @media screen and (min-width:$screen-sm-min) {
          justify-content: flex-start;
        }

        span.arrow-link--right {
          margin: 4px 0 0 15px;
          svg {
            width: 20px;
            height: 20px;
            fill: currentColor;
          }
        }
      }

      &:nth-child(1){
        @media screen and (min-width:$screen-md-min) {
          &:after {
            content: "";
            position: absolute;
            left: 100%;
            top: 0;
            width: calc((100vw - 1157px) / 2);
            height: 100%;
            background-color: inherit;
          }
        }
      }

      &:nth-child(2){
        background-color:rgba(255,255,255,0.8);
      }
    }
  }
}

// Cta-banner with quick-links
.cta-banner.cta-banner--contains-quick-links {
  overflow: visible;

  @media screen and (min-width:$screen-md-min) {
    margin-bottom: 1.5rem;
  }
}
.cta-banner__quick-links {
  margin: 2rem 0;

  @media screen and (min-width:$screen-md-min) {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(50%);
    margin: 0;
  }

  // When placed in banner quick links should not have a bottom margin
  .c-quick-links {
    margin-bottom: 0!important;
  }
}