﻿span.customSelect {
    border: 1px solid #d4d4d4;
    padding: 7px 9px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 100%;
    height: 51px;
    line-height: 36px;
    font-size: 18px;
    color: #989cad;
    margin-bottom: 30px;
    background: url(/Images/select-btn.jpg) right center no-repeat #fcfcfc;
}

select option {
    height: 30px;
    line-height: 30px;
}