﻿.navigation {
    border-bottom: 1px solid #d4d7d9;
    padding-top: 30px;
}

.navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.navigation ul li {
    float: left;
    width: 18.5%;
    display: block;
    margin: 0 .75%;
}

.navigation ul li a .title {
    display: block;
    color: #ef413d;
    text-align: center;
    font-size: 20px;
}

.navigation ul li a .title .icon-rt-pink {
    margin-left: 8px;
}

.navigation ul li a:hover {
    text-decoration: none;
}

.navigation ul li a:hover .large-icon.menu-1 {
    background: url(/Images/menu_hover_1.png) center center no-repeat;
}

.navigation ul li a:hover .large-icon.menu-2 {
    background: url(/Images/menu_hover_2.png) center center no-repeat;
}

.navigation ul li a:hover .large-icon.menu-3 {
    background: url(/Images/menu_hover_3.png) center center no-repeat;
}

.navigation ul li a:hover .large-icon.menu-4 {
    background: url(/Images/menu_hover_4.png) center center no-repeat;
}

.navigation ul li a:hover .large-icon.menu-5 {
    background: url(/Images/menu_hover_5.png) center center no-repeat;
}

.navigation ul li a .large-icon {
    display: block;
    height: 134px;
    margin-top: 30px;
}

.navigation ul li a .large-icon.menu-1 {
    background: url(/Images/menu-1.png) center center no-repeat;
}

.navigation ul li a .large-icon.menu-2 {
    background: url(/Images/menu-2.png) center center no-repeat;
}

.navigation ul li a .large-icon.menu-3 {
    background: url(/Images/menu-3.png) center center no-repeat;
}

.navigation ul li a .large-icon.menu-4 {
    background: url(/Images/menu-4.png) center center no-repeat;
}

.navigation ul li a .large-icon.menu-5 {
    background: url(/Images/menu-5.png) center center no-repeat;
}

.navigation ul li a .sml-description {
    color: $text-color;
    display: block;
    font-size: 14px;
    line-height: normal;
    margin: 30px auto;
    text-align: center;
    width: 72%;
    opacity: 0;
}

.red-nav {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
        color: $color-brand;
        display: block;
        font-size: 16px;
        margin: 8px 0;
    }
}

.orng-nav {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
        color: $color-brand;
        display: block;
        font-size: 16px;
        margin: 8px 0;
    }
}

.pop-navigation {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
        font-size: 20px;
        color: #fff;

        &:active,
        &:focus,
        &:hover {
            color: #fff;
            text-decoration: none;
            outline: 0;
        }
    }
}

.pop-navigation-sub {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
        color: #babfc2;
        display: block;
        font-size: 18px;
        padding: 5px 0;
        letter-spacing: 0;

        &:active,
        &:focus,
        &:hover {
            color: #fff;
            text-decoration: none;
            outline: 0;
        }
    }
}
//makes the episerver toolbar be at the front of the page
.mdc-top-app-bar, .mdc-menu-surface {
    z-index: 10 !important;
}
