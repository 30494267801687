//pagelinks styling is used on navigation pages such as http://localhost:53101/radgivning/ and in search-result pages
.pagelinks {
  &__list {

  }

  &__item {
      margin: 40px 0;
      h3 {
        margin-bottom: 0;

        a {
          @include themeColor();
          font-size: 20px;

          .sr__label {
            display: inline-block;
            background-color: #90989d;
            color: white;
            padding: 2px 5px;
            border-radius: 4px;
            margin-left: 10px;
          }

          &:hover {
            .sr__label {
              text-decoration: none;
            }
          }
        }
      }
      span {
        display: block;
        font-size: 14px;
        margin-bottom: 10px;
        white-space: nowrap;
      }
      em {
        font-style: normal;
        @include themeColor();
      }
  }
}