﻿.prettycheckbox, .prettyradio {
    margin: 16px 6px 16px 0;
    display: inline-block;
    padding: 0;
    position: relative;
}

.prettycheckbox a, .prettyradio a {
    background: url(/Images/check-box.png) no-repeat;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    height: 29px;
    margin: 0;
    vertical-align: middle;
    width: 29px;
}

.prettycheckbox a.error, .prettyradio a.error {
    background: url(/Images/checked-box-error.png) no-repeat;
}

.prettycheckbox a.error.checked, .prettyradio a.error.checked {
    background: url(/Images/checked-box.png) no-repeat;
}

.prettycheckbox label, .prettyradio label {
    color: $text-color;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    margin: 4px 0 0 10px;
    text-align: center;
    vertical-align: middle;
}

.prettycheckbox a:focus, .prettyradio a:focus {
    outline: 0;
}

.prettycheckbox a.disabled, .prettycheckbox label.disabled, .prettyradio a.disabled, .prettyradio label.disabled {
    cursor: not-allowed;
}