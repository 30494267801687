.shop-offer {
  position: relative;
  text-align: center;
  border: 1px solid #D4D4D4;
  display: block;
  height: 100%;

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    height: 50px;
    margin: 25px 15px 0;
  }

  &__image {
    margin: 30px auto 113px;
    img {
      max-height: 214px;
      width: auto;
    }
  }

  &__label {
    font-size: 16px;
    font-weight: 600;
    color: $text-color;
    margin: 10px;
    position: absolute;
    bottom: 61px;
    width: 100%;
  }
  &__price {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    @include themeBaggroundColor();
    color:white;

    font-size: 40px;
    font-weight: 600;
    padding: 2px 0;
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0,0,0,.3);
    opacity: 0;
    z-index: 0;
    transition: opacity .3s ease-in-out;
  }

  &:hover {
    text-decoration: none;

    &:after {
      opacity: 1;
    }
  }
}