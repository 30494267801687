﻿.panel {

    &.panel-default {
        background: none;
        border: none;
        box-shadow: none;
        margin-bottom: 0;
    }

    .panel-heading {
        background: none !important;
        border: none !important;
        border-radius: 0;
        padding-left: 0 !important;

        h4 {

            button {
                font-size: 17px;
                font-weight: 400;

                .bold-text {
                    font-weight: 600;

                    .privat & {
                        color: $color-brand;
                    }
                }

                .privat & {
                    color: $text-color;
                }

                &:active,
                &:focus,
                &:hover {
                    text-decoration: none;
                    outline: 0;
                }

            }
        }
    }
}

.panel-default {

    & > .panel-heading {
        background: none !important;
    }
}

.panel-group {
    margin-bottom: 0 !important;

    .panel + .panel {
        margin-top: 8px;
    }
}

.panel-body {
    border-top: none !important;

    .privat & {
        color: $color-brand;
        padding: 4px 20px;
    }
}
