@keyframes fadeIn {
  from {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes bounceIn {
  from, 40%, 60%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  40% {
    transform: scale3d(1.02, 1.02, 1.02);
  }



  60% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}


@keyframes slideInDown {
  from {
    transform: translate3d(0, -200%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  15%, 45%, 75% {
    transform: translate3d(-5px, 0, 0);
  }

  30%, 60%, 90% {
    transform: translate3d(5px, 0, 0);
  }
}
