$fullwidth-search-padding: 1.5rem;
$border-color: #E9EBEC;
$elevation-search: 0 2px 10px 0 rgba(#17191A,.05);

.fullwidth-search {
  position: relative;
  width: 100%;

  &__inner {
    background-color: #fff;
    box-shadow: $elevation-search;
    border-radius: $border-radius-base;

    .o-full-width-container--gradient &,
    .o-full-width-container--blue-gradient &,
    .o-full-width-container--blue & {
      background: none;
      box-shadow: none;
      border-radius: 0;
    }
  }

  &__input {
    font-size: 18px;
  }

  .form-group {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 60px;

    input[type=text] {
      flex: 2 1 auto;
      align-self: stretch;
      min-height: 60px;
      padding: 0 20px;
      background-color: #eee;
      border: none;
      font-size: 14px;
      line-height: 1.4;

      @media (min-width: $screen-md-min) {
        font-size: 18px;
      }

      .ie & {
        padding-right: 60px;
      }
    }

    input[type=submit] {
      flex: 0 1 auto;
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 60px;
      font-size: 14px;
      line-height: 1.2;
      @include themeBaggroundColor();

      @media (min-width: $screen-md-min) {
        font-size: 20px;
      }

      color: white;
      border: none;
    }

    .btn--triangle-shadow {
      @include btn--triangle-shadow(60px, 60, 120px);
    }
  }

  &__clear-btn {
    position: absolute;
    right: $fullwidth-search-padding;
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }

    .ie & {
      display: none !important;
    }
  }

  .address--invalid, .address--invalid-text, .address--valid {
    display: none;
  }

  &--addressSelected {
    .address--valid {
      position: absolute;
      right: 20px;
      top: 35px;
      width: 30px;
      height: 30px;
      display: block;
      transform: scale(0.15);
      transform-origin: top left;

      &:before {
        content: '';
        background-color: green;
        bottom: 45px;
        height: 30px;
        left: 20px;
        position: absolute;
        transform: rotate(45deg);
        transform-origin: top left;
        width: 75px;
      }

      &:after {
        content: '';
        background-color: green;
        bottom: 0;
        height: 30px;
        left: 52.5px;
        position: absolute;
        transform: rotate(-45deg);
        transform-origin: bottom left;
        width: 175px;
      }
    }
  }

  &--noAddressSelected {
    .address--invalid-text {
      display: block;
      color: red;

      .o-full-width-container--gradient &,
      .o-full-width-container--blue-gradient &,
      .o-full-width-container--blue & {
        color: #F6AAD7;
      }
    }

    .address--invalid {
      display: block;
      position: absolute;
      right: 20px;
      top: 40px;
      width: 30px;
      height: 30px;
      text-align: center;

      $height: 6px;

      span {
        position: absolute;
        display: inline-block;
        background-color: red;

        &:nth-child(1), &:nth-child(3) {
          top: 0;
          left: calc(50% - 3px);
          width: $height;
          height: $height;
        }

        &:nth-child(2) {
          top: 13px;
          left: 0;
          width: 30px;
          height: 3px;
        }

        &:nth-child(3) {
          bottom: 0;
          top: auto;
        }
      }
    }
  }

  &__loader {
    display: none;

    .fullwidth-search--searching & {
      display: block;
    }

    position: absolute;
    right: 14px;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    //so spinner is not positioned on top of delete X in ie
    .ie & {
      top: 10px;
    }

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    .circular-loader {
      animation: rotate 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;


      .loader-path {
        stroke-dasharray: 150,200;
        stroke-dashoffset: -10;
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke-linecap: round;
      }
    }
  }
}

.fullwidth-search__message {
    position:relative;
    background-color: #eee;
    padding: 20px;
    margin: 30px 0;
    animation: shake 2s .3s forwards;
}

.dawa-search--fiberpulje + .fullwidth-search__message {
  display: none;
}

.fullwidth-search__validation-message {
  display: none;
  @include themeColor();
}

.fullwidth-search__message p {
    margin-bottom: 0;
}

//text is shown via js if address is not valid
.dawa-search--js-helptext {
  display: none;
}


@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@keyframes color {
  0% {
    stroke: $link-color;
  }
  40% {
    stroke: $link-hover-color;
  }
  66% {
    stroke: $link-color;
  }
  80%, 90% {
    stroke: $link-hover-color;
  }
}


/*
  NEW LOGIC and design update

  As default the search field is shown
  If the user de-focus the search field, an x-icon is show and the user is able to clear the input field
  The user can search and submit an address

  If the user submits an address using autocomplete the search field is visually hidden and the selected address is shown as text instead

  The reset form button is shown when an address is selected
  When the user clicks the reset button, the form is resat and page reloaded

*/

.fullwidth-search--fiber {

  .form-group {
    height: 68px;
    margin-bottom: 0;

    input[type=text] {
      min-height: 68px;
      font-size: 18px;
      padding: 0 $fullwidth-search-padding;
      background-color: #fff;
      border-radius: $border-radius-base;
      border: 1px solid $border-color;
      outline: none;
      color: $header-color;

      .o-full-width-container--gradient &,
      .o-full-width-container--blue-gradient &,
      .o-full-width-container--blue & {
        box-shadow: 0 4px 15px 2px rgba(0,0,0, 0.2);
      }

      &:focus {
        border-color: $color-brand;

        .theme-yourelectricity & {
          border-color: $color-turquise;
        }
      }
    }
  }
  // Hide address lookup input when there is an address selected by the user
  &.fullwidth-search--addressSelected .form-group {
    display: none;
  }
  // Hide the default search message
  .fullwidth-search__message {
    display: none; // Indtast din adresse, så tjekker vi, om du kan få lynhurtig internet, TV og telefoni fra AURA Fiber.

    position: relative;
    margin: 0;
    padding: $fullwidth-search-padding;
    background-color: #fff;
    animation: none !important;
    border: 1px solid $border-color;
    border-top: none;
    border-radius: $border-radius-base;
    z-index: 1;

    .o-full-width-container--gradient &,
    .o-full-width-container--blue-gradient &,
    .o-full-width-container--blue & {
      text-align: center;
      padding: 0;
      background: none;
      border: 0;
      border-radius: 0;
    }
  }
  // When searching the fullwidth-search__message should always be hidden, also when class fullwidth-search--addressSelected is added to DOM
  &.fullwidth-search--addressSelected.fullwidth-search--searching {
    .fullwidth-search__message {
      display: none !important;
    }
  }
  // Show fullwidth-search__message when address is selected
  &.fullwidth-search--addressSelected {
    .fullwidth-search__message {
      display: grid;
      // Use this instead when we add icons
      //grid-template-columns: 20px 1fr;
      grid-template-columns: 1fr;
      grid-column-gap: 1rem;
    }
  }

  .address--invalid-text {
    margin-top: 1rem;
  }

  .address--status {
    text-align: center;

    @media (min-width: $screen-md-min) {
      font-size: 18px;
      line-height: 23px;
      margin: 0 0 52px;
    }

    h2 {
      margin-bottom: 1.125rem;

      @media (min-width: $screen-md-min) {
        margin-bottom: 1.75rem;
      }
    }

    .u-icon {
      margin: 0 0 12px;

      @media (min-width: $screen-md-min) {
        margin: 0 0 10px;
      }
    }
  }

  .address--info {
    display: block;
    font-size: 19px;
    line-height: 25px;
  }
}

// This is the static address - hidden as default
.static-dawa-address {
  display: none;
  margin: 2.5rem 0 $fullwidth-search-padding;

  // Show address when there is an address selected by the user
  &--show {
    display: block;
  }
}

// This is reset search result button - hidden as default
.reset-search-result-button {
  display: none;
  margin-top: $fullwidth-search-padding;

  // Show address when there is an address selected by the user
  &--show {
    display: block;
  }
}