@mixin btn--triangle-shadow($h: 50px, $h2: 50, $bW: 600px) {
  position: relative;
  flex: 1 1 auto;
  align-self: stretch;
  overflow: hidden;

  &:after {
    content:"";
    position: absolute;
    display: block;
    pointer-events: none;
    top:0;
    right:0;
    width: $h/2.6;
    height: $h;
    border-style: solid;
    border-width: $h $h/2.6 $h 0;
    border-color: transparent #000 transparent transparent;
    opacity: .2;
    transition: all .3s cubic-bezier(0.770, 0.000, 0.175, 1.000);;
  }

  &:hover, &:focus {
    &:after {
      $borderW: $bW;
      $borderH: $borderW * 2.6;
      $top: $bW / 2;
      //transform: translateX(-100%);
      top: -$top;
      border-width: $borderH $borderW $borderH 0;

      @media (min-width: $screen-lg-min) {
        $borderW: $bW * 2;
        $borderH: $borderW * 2.6;
        $top: $borderW / 2;
        //transform: translateX(-100%);
        top: -$top;
        border-width: $borderH $borderW $borderH 0;
      }
    }
  }
}

@mixin themeColor(){
  color: $color-brand;

  .theme-yourelectricity & {
    color: $color-turquise;
  }
}

@mixin themeColorLight(){
  color: $color-brand-light;

  .theme-yourelectricity & {
    color: $color-turquise;
  }
}

@mixin themeDarkenColor(){
  color:darken($color-brand, 15%);

  .theme-yourelectricity & {
    color:darken($color-turquise, 15%);
  }
}

@mixin themeBaggroundColor(){
  background-color: $color-brand-light;

  .theme-yourelectricity & {
    background-color: $color-turquise;
  }
}

@mixin themeBaggroundGradient(){
  background: linear-gradient(120deg, $color-brand-gradient-1, $color-brand-gradient-2);

  .theme-yourelectricity & {
    background-color: $color-turquise;
  }
}

@mixin themeMenuBaggroundColor(){
  background-color: darken($color-brand-light, 5%);

  .theme-yourelectricity & {
    background-color: darken($color-turquise, 5%);
  }
}

@mixin themeDarkenBaggroundColor(){
  background-color: darken($color-brand-light, 15%);

  .theme-yourelectricity & {
    background-color: darken($color-turquise, 15%);
  }
}

@mixin themeBorderColor($borderWidth: "1px", $borderStyle: "solid"){
  border: $borderWidth $borderStyle $color-brand-light;

  .theme-yourelectricity & {
    border: $borderWidth $borderStyle $color-turquise;
  }
}

@mixin themeFill(){
  fill: $color-brand-light;

  .theme-yourelectricity & {
    fill: $color-turquise;
  }
}

@mixin themeDarkenFill(){
  fill:darken($color-brand-light, 15%);

  .theme-yourelectricity & {
    fill: darken($color-turquise, 15%);
  }
}