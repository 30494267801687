.custom-form,.EPiServerForms {
    position: relative;
    @include clearfix();
    @extend .rte-block;

    select:not(.c-selectbox__select) {
        background: #fcfcfc;
        border: 1px solid #d4d4d4;
        border-radius: 5px;
        padding: 10px;
        margin: 10px 0;
        width: 100%;
        min-height:47px;

        option {
            padding: 10px;
            min-height:47px;
        }

        &[multiple]{
            padding: 0;
        }
    }

    .input-group {
        &-addon {
            position: absolute;
            border-right: 1px solid #d4d4d4;
            padding: 11px 15px 11px 10px;

            + input {
                padding-left: 75px !important;
            }
        }
    }

    textarea,
    input[type="text"],
    input[type="number"],
    input[type="url"],
    input[type="email"],
    input[type="password"],
    #dawa-address {
        border: 1px solid #8c9398;
        padding: 1rem;
        width: 100%;
        height:48px;
        margin-bottom: 1rem;
        border-radius: 3px;

        &.input-validation-error, &.error {
            border: 1px solid $color-error;
        }

        &.input--ghost {
            background: transparent;
            border:1px solid white;
            border-radius: 0;
            color:white;

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: white;
                opacity: 1;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: white;
                opacity: 1;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: white;
                opacity: 1;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: white;
                opacity: 1;
            }
        }

        &[disabled] {
            background-color: $gray-lighter;
        }
    }

    &--submit {
        @media (min-width: $screen-md-min) {
            margin-top: 40px;
        }
        border-radius: 5px;
        height:47px;
    }

    textarea {
        &.input-validation-error {
            border: 1px solid $color-error;
        }
    }

    hr {
        border-top: 1px solid #d4d4d4;
        margin: 60px 0;
    }

    .row {
        margin-bottom: 1rem;
    }

    .check-group {
        &.error {
            &:after {
                content: "Vælg som minimum én";
                color:$color-error;
                font-size: 14px;
                font-weight: normal;
                position: relative;
                margin-bottom: 15px;
                margin-left: 1rem;
            }
        }
    }

    .checkbox-list {
        li {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            input {
                margin: 0;
            }
            label {
                margin: 0 0 0 10px;
            }
        }
    }

    .error-message {
        padding: 0;
        background: transparent !important;
        color: $color-error;
        margin: 15px 0;
        font-weight: normal;
        width: auto !important;
        float: none;
        min-height: 0;
        font-size: 14px;
    }

    .dropdown-info {
        cursor: pointer;
        
        &__img {
            width: 20px;
        }
    }
}

.use-datetimepicker {
    position: relative;

    > .glyphicon-calendar {
        position: absolute;
        top: 7px;
        right: 10px;
    }
}

.required-check--label.error {
    &:after {
        content: "Husk at sætte flueben hér";
        color:$color-error;
        font-size: 14px;
        font-weight: normal;
        position: absolute;
        display: block;
        left: 2rem;
        top: calc( 100% - 10px);
    }
}

input[type=checkbox], input[type=radio] {
    &.input-validation-error {
        outline: 1px solid $color-error;
    }
}

input[type=radio].required-check-in-group.error {
    outline: 1px solid $color-error;
}

.newsletter-signup-form {
    .row {
        margin-bottom: 0;
    }
    .custom-form {
        margin-top: 0;
    }
    label {
        margin-top: 10px;
    }

    &__explanation-text {
        @media (min-width: $screen-sm-min) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        margin: 10px 0 10px 0;
    }

    .fullwidth-search {

        #dawa-address {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            min-height: 0;
        }
    }
}

.label__checkbox {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    input {
        flex: 0 0 auto;
        margin-top: 6px;
        margin-right: 5px;
    }
    p {
        flex: 0 1 auto;
    }
}

#fiber-pulje {
    input[type="checkbox"].input-validation-error, input[type="checkbox"].input-validation-error:focus {
        outline: 1px solid $color-error;
    }
}

/* Used for Fiber form */
.row--bottom-margin-0 {
  margin-bottom: 0!important;
}
.row--bottom-margin-m {
  margin-bottom: 2.5rem!important;
}
.row--bottom-margin-l {
  margin-bottom: 3.5rem!important;
}

.fiber-form-header-icon {
  &__item {
    display: flex;
    align-items: center;

    .c-icon-button {
      margin-left: 0.5rem;
    }
  }
}

/* Used for pairing form */
.login-info-block {
  margin-top: -70px;
}

#pairing-form {
  position: relative;
  margin-top: 32px;
  padding-top: 30px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 360px;
    height: 1px;
    background: #000;
    z-index: 2;
  }
}
