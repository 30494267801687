.page-spinner {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  background: rgba(0,0,0,.3);
  opacity: 0.6;
  justify-content: center;
  align-items: center;

  &.in-your-face {
    display: block;
  }

  > div {
    position:absolute;
    top: 50%;
    left: 50%;

  }
}



$spinkit-spinner-margin: 40px auto !default;
$spinkit-size: 40px !default;
$spinkit-spinner-color: #333 !default;

.sk-wave {
  $rectCount: 5;
  $animationDuration: 1.2s;
  $delayRange: 0.4s;

  margin: $spinkit-spinner-margin;
  width: $spinkit-size * 1.25;
  height: $spinkit-size;
  text-align: center;
  font-size: 10px;

  .sk-rect {
    background-color: $spinkit-spinner-color;
    height: 100%;
    width: 6px;
    display: inline-block;
    animation: sk-waveStretchDelay $animationDuration infinite ease-in-out;
  }

  @for $i from 1 through $rectCount {
    .sk-rect#{$i} { animation-delay: - $animationDuration + $delayRange / ($rectCount - 1) * ($i - 1); }
  }
}

@keyframes sk-waveStretchDelay {
  0%, 40%, 100% { transform: scaleY(0.4) }
  20% { transform: scaleY(1.0) }
}