@import 'partials/_colors';

/* Bootstrap */
@import 'partials/_variables';
@import 'bootstrap';
@import 'jqueryui/jquery-ui';

/* Sitestyles */
@import "partials/general/mixins";
@import 'partials/_fonts';
@import 'partials/general/_general';
@import 'partials/general/_markup';
@import 'partials/general/_svg-icons';
@import "partials/general/animations";
//@import 'partials/general/_equalheight';

//specific pagestyling
@import "partials/content/pages/pagelinks.scss";

//content
@import "partials/content/_accordion.scss";
@import "partials/content/_autocomplete.scss";
@import "partials/content/_banner.scss";
@import "partials/content/_breadcrumb.scss";
@import "partials/content/_buttons.scss";
@import "partials/content/_checkbox-toggle-visibility.scss";
@import "partials/content/_checkbox.scss";
@import "partials/content/_colorbox.scss";
@import "partials/content/_contact-box.scss";
@import "partials/content/_contracts.scss";
@import "partials/content/_ctabox.scss";
@import "partials/content/_datetimepicker.scss";
@import "partials/content/_dropdown.scss";
@import "partials/content/_dynamic-content-box.scss";
@import "partials/content/_dynamic-sub-nav.scss";
@import "partials/content/_event.scss";
@import "partials/content/_faktabox.scss";
@import "partials/content/_focusEntriesBanner.scss";
@import "partials/content/_footer.scss";
@import "partials/content/_forms--EPiServerForms.scss";
@import "partials/content/_forms.scss";
@import "partials/content/_fullwidth-search.scss";
@import "partials/content/_jumbotron.scss";
@import "partials/content/_labels.scss";
@import "partials/content/_links.scss";
@import "partials/content/_lists.scss";
@import "partials/content/_map.scss";
@import "partials/content/_menu.scss";
@import "partials/content/_modals.scss";
@import "partials/content/_module.scss";
@import "partials/content/_navigation-inner.scss";
@import "partials/content/_navigation.scss";
@import "partials/content/_operation.scss";
@import "partials/content/_page-spinner.scss";
@import "partials/content/_panels.scss";
@import "partials/content/_pre-footer.scss";
@import "partials/content/_pricetrendsview.scss";
@import "partials/content/_pricing.scss";
@import "partials/content/_promotion-splash.scss";
@import "partials/content/_radiobutton.scss";
@import "partials/content/_rep-person.scss";
@import "partials/content/_search.scss";
@import "partials/content/_selfservice.scss";
@import "partials/content/_selvservice-login-box.scss";
@import "partials/content/_social.scss";
@import "partials/content/_table.scss";
@import "partials/content/_tabs.scss";
@import "partials/content/_texts.scss";
@import "partials/content/_tooltipster.scss";
@import "partials/content/_union.scss";
@import "partials/content/contentarea.scss";
@import "partials/content/slider-toggle.scss";

//blocks - this is the way to go
@import "partials/content/container-blocks/grid-flex.scss";
@import "partials/content/container-blocks/row-flex.scss";
@import "partials/content/blocks/add-account-block.scss";
@import "partials/content/blocks/advantages-block.scss";
@import "partials/content/blocks/alert.scss";
@import "partials/content/blocks/columnbox.scss";
@import "partials/content/blocks/content-box-block.scss";
@import "partials/content/blocks/flytteportal-entries.scss";
@import "partials/content/blocks/image-explanation.scss";
@import "partials/content/blocks/linksbanner-block.scss";
@import "partials/content/blocks/news-list.scss";
@import "partials/content/blocks/rte-block.scss";
@import "partials/content/blocks/shop-offer.scss";

// Helpers are imported last to ensure they "rule to CSS world"
@import 'partials/general/_helpers';
@import "Utilities/mixins";

// All components
@import "Components/mediaQueryIndicator.scss";

#pageslide {
    background: #002341;
    display: none;
    height: 100%;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 320px;
    z-index: 999999;
}

@media screen and (max-width:$screen-md-min) {
    .store-glass-txt {
        bottom: 30px;
        left: 38px;
    }

    .greyscaleHolder a {
        width: 100%;
    }

    .navigation ul li a .title {
        font-size: 18px;
    }

    .navigation-inner ul li {
        width: auto;
        margin: 0 12px 0 0;
    }

    .navigation-inner ul li a .title {
        font-size: 16px;
        padding: 12px 0 0 4px;
    }

    .clear-xs {
        clear: both;
        display: block;
    }

    .col-20p {
        float: left;
        margin-bottom: 30px;
        width: 33.33%;
    }
}

@media screen and (max-width:769px) {
    .greyscaleHolder a {
        width: 100%;
    }

    .navigation ul li {
        width: 16%;
        margin: 0 2%;
    }

    .navigation ul li a .large-icon.menu-1 {
        background: url(/Images/ipad-menu.png) 4px top no-repeat;
    }

    .navigation ul li a .large-icon.menu-2 {
        background: url(/Images/ipad-menu.png) -154px top no-repeat;
    }

    .navigation ul li a .large-icon.menu-3 {
        background: url(/Images/ipad-menu.png) -310px top no-repeat;
    }

    .navigation ul li a .large-icon.menu-4 {
        background: url(/Images/ipad-menu.png) -464px top no-repeat;
    }

    .navigation ul li a .large-icon.menu-5 {
        background: url(/Images/ipad-menu.png) -618px top no-repeat;
    }

    .navigation ul li a:hover .large-icon.menu-1 {
        background: url(/Images/ipad_menu_hover.png) 4px top no-repeat;
    }

    .navigation ul li a:hover .large-icon.menu-2 {
        background: url(/Images/ipad_menu_hover.png) -154px top no-repeat;
    }

    .navigation ul li a:hover .large-icon.menu-3 {
        background: url(/Images/ipad_menu_hover.png) -310px top no-repeat;
    }

    .navigation ul li a:hover .large-icon.menu-4 {
        background: url(/Images/ipad_menu_hover.png) -464px top no-repeat;
    }

    .navigation ul li a:hover .large-icon.menu-5 {
        background: url(/Images/ipad_menu_hover.png) -618px top no-repeat;
    }

    .navigation ul li a .sml-description {
        margin: 0 auto 30px;
        width: 90%;
    }

    .font-22 {
        font-size: 18px;
    }

    #pageslide #slideMenu {
        display: block;
    }
}

@media screen and (max-width:640px) {
    .menu {
        font-size: 16px;
        padding-right: 0;
    }

    .xs-mt80 {
        margin-top: 80px;
    }

    .banner-advice {
        height: auto;
        background-color: #edefef;
    }

    .banner-advice h1 {
        margin-top: 14px;
    }

    .business-link, .privat-link {
        margin-top: 10px;
        float: right;
    }

    .navigation-inner ul li {
        margin: 12px 12px 0 0;
        max-width: 168px;
        min-width: 152px;
        width: 43%;
    }

    h2 {
        font-size: 32px;
        -ms-word-wrap: break-word;
        word-wrap: break-word;
    }

    .mobile-icon {
        display: none;
    }

    .sea-bg {
        background: url(/Images/banner-advice-2.jpg) 0 0/contain no-repeat;
        height: auto;
        padding-top: 100px;
        padding-bottom: 40px;
    }

    .mt0-xs {
        margin-top: 0;
    }

    .ipad-bg-sm {
        background: url(/Images/store-section-1.jpg) scroll center/contain no-repeat;
        height: auto;
        margin-top: 40px;
        padding-top: 65px;
    }

    .store-sec-2 {
        background: url(/Images/store-section-2.jpg) scroll center/contain no-repeat;
        height: auto;
        margin-top: 40px;
        padding-top: 40px;
    }

    .banner-corporate {
        background: url(/Images/banner-corporate-mob.jpg) scroll center/contain no-repeat;
        height: auto;
        padding-top: 200px;
    }

    .col-20p {
        width: 50%;
    }

    .inner-grey {
        padding-bottom: 30px;
    }

    .mb40-xs {
        margin-bottom: 40px;
    }

    .banner-handel h1 {
        color: $text-color;
        font-size: 40px;
        letter-spacing: -2px;
        margin-top: 30px;
    }

    .disp-table .disp-table-cell.no-table-xs {
        display: block;
        float: none;
        margin-bottom: 30px;
        vertical-align: top;
    }

    .banner-fiber h1 {
        color: $text-color;
        font-size: 28px;
        letter-spacing: -2px;
        line-height: 30px;
    }

    .banner-fiber .privat-link.mt36 {
        margin-top: 20px;
    }

    .banner-fiber .business-link, .banner-fiber .hero-link-2, .banner-fiber .privat-link {
        float: left;
    }

    .banner {
        background: url(/Images/top-banner.jpg);
        height: auto;
        margin-top: 16px;
    }

    .banner h1 {
        margin: 0 0 20px;
    }

    .section-2 {
        background: url(/Images/section-2.jpg) scroll center/contain no-repeat;
        height: auto;
        padding-top: 100px;
    }

    .banner-install h1 {
        color: $text-color;
        font-size: 28px;
        letter-spacing: -2px;
        line-height: 30px;
    }

    .banner-service {
        background: url(/Images/customer-service.jpg) scroll center/contain no-repeat #f7f7f7;
        height: auto;
    }
}

@media screen and (max-width:420px) {
    .banner-corporate {
        background: url(/Images/banner-corporate-mob.jpg) scroll center/contain no-repeat;
        height: auto;
        padding-top: 120px;
    }

    .banner-corporate h1 {
        color: $text-color;
        font-size: 40px;
        letter-spacing: -2px;
        line-height: 42px;
    }

    .col-20p {
        width: 100%;
    }

    .telephone {
        color: #fff;
        font-size: 52px;
        margin-top: 18px;
    }

    .banner-elnet {
        background: url(/Images/banner-elnet.jpg) scroll center/contain no-repeat;
        height: auto;
    }

    .section-2 {
        background: url(/Images/section-2.jpg) scroll center/contain no-repeat;
        height: auto;
        padding-top: 40px;
    }

    .white-logo {
        float: left;
        max-width: 30%;
    }

    .menu-main.bb1 {
        background: #5b666e;
    }
}


/* Content links */
.content-links.white-font {
  .c-link {
   color: white;
  }
}

.content-links ul li,
.content-links ol li {
  font-size: 17px;
  line-height: 1.4;
  color: $text-color;
  margin-bottom: 1rem;
}


.content-links-text a {
    text-decoration: none;
    border-bottom: 1px solid #fff;
}

.selfservice .content-links-text a.btn {
    border: solid 1px $color-brand;
    text-decoration: none;
    color: $color-brand;
}

.selfservice .content-links-text a:visited {
    color: $link-visited-color;
}

.selfservice .content-links-text a.btn:hover {
    color: white;
}


.no-border a {
    border-bottom: 0px;
}

.no-border a:visited {
    border-bottom: 0px;
}

.subPageChildren ul {
    margin-top: 5px;
}

.grey-link {
    color: $text-color;
    display: block;
    margin-top: 29px;
    font-size: 24px;
}

.grey-link .icon-arrow-rt {
    background: url(/Images/grey-rt.png) right top no-repeat;
    display: inline-block;
    height: 30px;
    margin-bottom: -12px;
    width: 30px;
}

.inner-grey {
    margin-top: 0;
}

iframe {
    position:relative;
    border: none;
    overflow: hidden;
    min-height: 1px;
    max-width:100%;
}

.iframeContainer #podioform {
    height: 1200px;
}

.white-font p {
    color: #fff;
}

.col-lg-5ths, .col-md-5ths, .col-sm-5ths, .col-xs-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

.calculator .header {
    background: red;
    color: #fff;
    font-weight: 600;
}

.calculator tr td {
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 65px;
    padding-right: 5px;
}

.calculator tr .co2, .calculator tr .price, .calculator tr .result-co2, .calculator tr .result-price {
    text-align: right;
}

.calculator .resultrow {
    border-top: 1px solid $text-color;
    border-bottom: 1px solid $text-color;
}

.white-box-mod {
    background: #fff;
    padding: 80px 0;
}

.gray-box-mod {
    background: #cbced1;
    padding: 80px 0;
}

.addressFound:nth-child(odd) {
    background: #cbced1;
}

.addressFound:hover {
    background: #EF413D;
}

.addressFound .btn-primary-spl {
    margin-top: 0;
}

.custom-form textarea {
  height: auto;
  min-height: 100px;
  width: 100% !important;
}

.adrInfo {
    display: inline-block;
}

.custom-form label {
    margin-right: 10px;
}

@media (min-width:768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width:992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width:1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}

.panel .panel-heading h4 button {
    color: $text-color;
}

.custom-form input[type=password] {
    background: #fcfcfc;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
}

.arrow-rt-pvt {
    margin: 6px 0 0 30px;
}

.module h2 {
    margin-top: 80px;
}

@media (max-width:769px) {
    .btn-submit-spl {
        font-size: 22px;
        margin-top: 0;
        margin-bottom: 30px;
    }

    .operation table {
        width: 100%;
    }

    .module .link-list-wt {
        font-size: 18px;
    }

    .module .link-list-wt img {
        width: 16px;
    }

    .module.pink-box-mod {
        padding: 40px 0;
    }

    .email-txt {
        font-size: 18px;
        margin-top: 10px;
    }

    .banner-fiber h1 {
        font-size: 32px;
        line-height: 35px;
    }

    .banner-fiber h1 span {
        font-size: 20px;
    }

    .dark-pink-box {
        padding: 40px 0;
    }

    .light-grey {
        padding-top: 40px;
    }

    .pb80 {
        padding-bottom: 40px;
    }

    .pt80 {
        padding-top: 40px;
    }

    .btn-primary-spl {
        font-size: 22px;
    }

    .module {
        border-bottom: 1px solid #edeeee;
    }

    ol, ul {
        margin: 0;
        padding: 0 0 0 16px;
    }

    h2 {
        margin: 0 0 10px;
    }

    .grey-link {
        font-size: 18px;
    }

    .grey-link img {
        width: 16px;
    }

    div .text-sp-center {
        margin: 0;
    }

    .module h2 {
        margin-top: 40px;
        font-size: 33px;
    }

    .inner-grey {
        padding-bottom: 25px;
        padding-top: 25px;
    }

    .pink-box {
        padding: 45px 0 75px;
    }

    .module .link-list-grey {
        font-size: 18px;
    }

    .link-list-grey img {
        width: 16px;
    }
}

@media screen and (max-width:640px) {
    .navigation-inner ul li {
        width: 100%;
        font-size: 12px;
    }
}

@media screen and (max-width:1024px) {
    .banner-fiber h1 {
        margin-top: 40px;
    }

    .inner-menu-2.eq-25 li {
        margin-right: 0;
        width: 25%;
    }

    .inner-menu-2 {
        padding: 1px 0;
    }

    .navigation-inner ul li {
        width: 20%;
        margin: 0;
    }

    .navigation-inner ul li a .title {
        font-size: 14px;
    }

    .navigation-inner ul li a .title .icon-rt-pink {
        margin-left: 0;
    }

    .white-fnt {
        color: #fff;
    }
}

.col-xs-12.mob-me.menu-title.menu-main.text-center.bb1 a:first-child {
    padding: 0;
    font-size: 20px;
    text-decoration: none;
}

.text-sp-center {
    margin-top: 40px;
}

.mt0 {
    margin-top: 0;
}

.navigation-inner ul li a .large-icon.menu-1 {
    background: url(/Images/menu-sm-1-pri.png) left center no-repeat;
}

.navigation-inner ul li a .large-icon.menu-2 {
    background: url(/Images/menu-sm-2-pri.png) left center no-repeat;
}

.navigation-inner ul li a .large-icon.menu-3 {
    background: url(/Images/menu-sm-3-pri.png) left center no-repeat;
}

.navigation-inner ul li a .large-icon.menu-4 {
    background: url(/Images/menu-sm-4-pri.png) left center no-repeat;
}

.navigation-inner ul li a .large-icon.menu-5 {
    background: url(/Images/menu-sm-5-pri.png) left center no-repeat;
}

.arrow-icon {
    background: url(/Images/arr-sprite-pink.png) center 0 no-repeat;
}

.white-fnt {
    color: #fff;
}

.menu:active, .menu:focus, .menu:hover {
    color: $color-brand;
    text-decoration: none;
    outline: 0;
}

.page-title {
    color: $text-color;
    display: inline-block;
    font-size: 18px;
    height: 54px;
    line-height: 54px;
    margin-top: 32px;
    text-transform: uppercase;
}

.page-title i.icon-1 {
    background: url(/Images/icon-1.png) no-repeat;
    display: inline-block;
    float: right;
    height: 54px;
    margin-left: 20px;
    width: 54px;
}

.page-title:hover {
    color: $color-brand;
    text-decoration: none;
}

.page-title:hover i.icon-1 {
    background: url(/Images/icon-1-pri.png) 0 -3px no-repeat;
}

.page-title img {
    max-width: 24px;
    margin-right: 5px;
}

.textbg-xs {
    min-height: 415px;
}

.textbg-xs-split {
    border-bottom: 1px solid #979ea3;
}

.pink-box, .red-box {
    padding: 80px 0;
}

.opration-tbl {
    width: 95%;
}

.opration-tbl th {
    background-color: #fcfcfc;
    padding: 5px;
}

.opration-tbl tr {
    border: 1px solid #4d4d4d;
}

.opration-tbl td {
    padding: 5px;
}

.fb-icon {
    background-image: url(/Images/fb-grey.png);
    height: 26px;
    margin-left: 10px;
    width: 26px;
}

.fb-icon:hover {
    background-image: url(/Images/fb-pink.png);
    height: 26px;
    margin-left: 10px;
    width: 26px;
}

.ln-icon {
    background-image: url(/Images/in-grey.png);
    height: 26px;
    margin-left: 10px;
    width: 26px;
}

.ln-icon:hover {
    background-image: url(/Images/in-red.png);
    height: 26px;
    margin-left: 10px;
    width: 26px;
}

@media screen and (max-width:769px) {
    .arrow-rt-pvt {
        margin: 6px 0 0 0px;
    }
}

@media screen and (max-width:992px) {
    .col-20p {
        width: 20%;
        float: left;
    }

    .arrow-rt-pvt {
        margin: 6px 0 0 0px;
    }

    .banner-fiber {
        background: url(/Images/banner-fiber.jpg) center/cover no-repeat;
        height: 300px;
    }
}

a[href^=tel] {
    color: inherit;
    text-decoration: none;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

/*SelfService extentions*/
.login-btn {
    border: 2px solid $color-brand;
    color: $color-brand;
    display: inline-block;
    @media screen and (max-width: 992px) {
        font-size: 14px;
        margin-top: 42px;
    }
    font-size: 20px;
    margin-top: 38px;
    padding: 3px 10px 3px 20px;
    text-transform: uppercase;
    white-space: nowrap;
}

.login-btn > span {
    display: inline-block;
    float: left;
    margin-top: 4px;
}

.login-btn:hover {
    background: $color-brand;
    color: #fff;
    text-decoration: none;
}

.login-btn:hover .login-icon {
    background-position: 0 -26px;
    @media screen and (max-width: 992px) {
        background-position: 0 -16px;
    }
}

.login-btn .login-icon {
    background: url("/Images/login-icon.jpg") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    display: inline-block;
    height: 26px;
    margin-left: 10px;
    vertical-align: top;
    width: 17px;

    @media screen and (max-width: 992px) {
        background-size: 10px 32px;
        height: 16px;
    }
}

.login-btn .login-icon.out {
    background-image: url("/Images/logout-icon.jpg");
}

.modal-content .nav-tabs {
    background: $color-brand;
    padding: 2px 2px 0;
    border: 0;
    margin-bottom: -1px;
}

.modal-content .nav-tabs > li > a {
    padding: 10px 20px;
    background: #eef2f2;
    color: $color-brand;
    border-radius: 0;
    border: 0;
    border-bottom: 2px solid $color-brand;

    &:focus,
    &:hover {
        border: 0;
        border-bottom: 2px solid $color-brand;
    }
}

.modal-content .nav-tabs > li.active > a {
    background: #fff;
    border-color: #fff;
    color: #555;

    &:focus,
    &:hover {
        border: 0;
        border-bottom: 2px solid #fff;
    }
}

.nemid-login-page {
    background: url(/Images/lock-full-bg.jpg) no-repeat right top;
    background-position: right -50px top;
}

.nemid-login-wrap {
    border: 2px solid #d9018a;
    padding: 20px;
    min-height: 301px;
    background: url('/Images/lock-bg.jpg') no-repeat right top;
}

.nemid-login-wrap .nemid-login {
    @media (min-width: $screen-xs-min) {
        overflow: hidden;
    }
}

.nemid-login-wrap .inst-box {
    @media (min-width: $screen-xs-min) {
        float: left;
        border-left: 1px solid #ccc;
        margin-bottom: -500px;
        margin-left: 20px;
        padding-bottom: 500px;
        padding-left: 20px;
    }
}

.nemid-login-wrap .login-box {
    @media (min-width: $screen-xs-min) {
        float: left;
    }
}

.nemid-login-wrap h4 {
    font-size: 30px;
    color: $color-brand;
    margin-top: 20px;
}

.modal-dialog.login {
    margin-top: 10%;

    @media (min-width: $screen-sm-min) {
        max-width: 632px;
    }
}


.modal-dialog.removeAccount {
    margin-top: 10%;

    @media (min-width: $screen-sm-min) {
        max-width: 600px;
        max-height: 400px;
    }
}

.modal-dialog.windModal {
    margin-top: 10%;

    @media (min-width: $screen-sm-min) {
        max-width: 440px;
        max-height: 670px;
    }
}

.modal-dialog.pleaseWait {
    margin-top: 10%;

    @media (min-width: $screen-sm-min) {
        max-width: 600px;
        max-height: 300px;
    }
}


/* above is moved*/
.banner-31 {
    background: url(/Images/banner-3.jpg) no-repeat center center;
    height: 415px;
}

.banner-31 h1 {
    color: $text-color;
    font-size: 48px;
    letter-spacing: -2px;
    margin-top: 144px;
}

.pricing {
    color: $color-brand;
    text-align: center;
    font-size: 50px;
}

.pricing .price {
    margin-bottom: 0;
}

.pricing span {
    font-size: 20px;
}

.pricing2 {
    font-size: 55px;
    color: $color-brand;
    letter-spacing: -0.5px;
    text-align: center;
    line-height: normal;
    margin: 26px 0;
}

.pricing2 span {
    display: block;
    font-size: 12px;
}

.pricing2 span i {
    background: url(/Images/arrow-right-privat.png) no-repeat left center;
    display: inline-block;
    height: 10px;
    width: 5px;
}

.btn-spl {
    background: none repeat scroll 0 0 #fdfdfd;
    border: 1px solid $color-brand !important;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: $color-brand !important;
    font-size: 32px;
    letter-spacing: -0.25px;
    margin: 20px 0 10px;
    padding: 0 30px;
}

.btn-spl:hover {
    background: $color-brand;
    color: #fff !important;
}

/* .font-normal {
    font-weight: normal;
    margin: 16px 0 0;
} */

.custom-form.v2 input[type="text"] {
    margin-top: 0;
}

hr.mt20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

label small {
    font-size: 80%;
}

input.mt10 {
    margin-top: 10px;
}

.npt {
    padding-top: 0 !important;
}

hr.less-margin {
    margin: 30px 0 20px !important;
}

.normal-link {
    color: #444444;
}

.ml5 {
    margin-left: 5px;
}

.price-det {
    color: $color-brand;
    font-size: 20px;
}

.custom-form.v3 {
    margin-top: 0;
}

.nmb {
    margin-bottom: 0 !important;
}

.reg-txt {
    color: $text-color;
}

.selfservice-tbl {
    display: table !important;
}

.selfservice-tbl-row {
    height: 25px;
}

.selfservice-tbl tr td {
    border-top: 0 !important;
}

.selfservice-tbl tr:nth-child(even) {
    background: #edeeef;
}

.selfservice-tbl tr:nth-child(odd) {
    background: #FFF;
}


.selfservice-tbl-row:hover {
    background-color: $color-brand;
    color: white;
}

.with-menu .title-img {
    max-width: 78%;
    vertical-align: middle;
}

.with-menu .pink-box-rounded p.telephone {
    font-size: 66px;
}

.with-menu .price-det {
    font-size: 12px;
}

.with-menu .pricing.price-det2 {
    font-size: 40px !important;
}

.mt60 {
    margin-top: 60px;
}

.accord-nav > li ul {
    display: block;
}

.left-nav--xs .accord-nav {
    max-width: none;
}

.mr40 {
    margin-right: 40px;
}

.profile-data {
    background: none repeat scroll 0 0 #5b666e;
    border-top: 20px solid $color-brand;
    padding-top: 20px;
}



.profile-data .prof-name {
    color: #d5d7d9;
    text-align: center;
    font-size: 15px;
    letter-spacing: normal;
}

.profile-data .prof-menu .prof-ctrl {
    border-top: 1px solid #6b767e;
    color: #fff;
    display: block;
    padding: 15px 20px;
    line-height: normal;
    background: url(/Images/menu-ar-1.png) no-repeat 8px center #4a545c;
    outline: none;
}

.profile-data .prof-menu .prof-ctrl .icon-dwn {
    background: url("/Images/prof-arrow.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    display: inline-block;
    height: 14px;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 14px;
}

.profile-data .prof-menu .prof-ctrl.collapsed {
    background: url(/Images/menu-ar-1.png) no-repeat 8px center #5b666e;
}

.profile-data .prof-menu .prof-ctrl.collapsed .icon-dwn {
    background: url("/Images/prof-arrow.png") no-repeat scroll 0 -14px rgba(0, 0, 0, 0);
}

.profile-data .prof-menu .prof-ctrl:hover {
    background: url(/Images/menu-ar-1.png) no-repeat 8px center #4a545c;
    color: #fff;
    text-decoration: none;
}

.profile-data .prof-menu .prof-ctrl:focus,
.profile-data .prof-menu .prof-ctrl:active {
    color: #fff;
    text-decoration: none;
}

.profile-data .prof-menu .prof-head {
    position: relative;
}

.profile-data .prof-menu .prof-head .edit-prof {
    background: url("/Images/edit-icon.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    height: 14px;
    position: absolute;
    right: 40px;
    top: 20px;
    width: 14px;
}

.profile-data .prof-menu .prof-head .notify-num {
    background: none repeat scroll 0 0 $color-brand;
    border: 1px solid #fff;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    font-size: 12px;
    height: 22px;
    letter-spacing: normal;
    line-height: normal;
    overflow: hidden;
    padding-top: 3px;
    text-align: center;
    vertical-align: middle;
    width: 22px;
    margin-left: 30px;
    margin-top: -4px;
    color:#fff;
}

.profile-data .prof-menu .data-box {
    font-size: 13px;
    padding: 10px 20px 10px 20px;
    background: #4a545c;
    position:relative;
}

.profile-data .prof-menu .data-box p {
    letter-spacing: normal;
    color: #d5d7d9;
}

.profile-data .prof-menu .mkt-accept {
    background: url(/Images/tick-icon.png) no-repeat right center;
    margin-right: -15px;
    margin-bottom: 0;
}

.wind-header {
    margin: 0;
    padding: 23px 0 17px 0;
    background: $color-brand;
    color: #ffffff;
    text-align: center;
    background-repeat: no-repeat;
    background-position: 20px center;
    font-size: 37px;
}

.wind-p {
    color: $color-brand;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    font-size: 25px;
}

.wind-hr {
    margin-left: -5px;
    margin-right: -5px;
}

.buttons-div {
    margin-left: auto;
    margin-right: auto;
    font-size: 0;
    width: 100%;
    text-align: center;
}

.button-text {
    font-size: 25px;
}

.buttons-div > button {
    margin-top: 0;
    float: none;
}

.change-account {
    color: $color-brand !important;
    text-decoration: none !important;
    padding-left: 25px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 0px;
}

.change-account select option {
    border: 1px solid $color-brand !important;
    margin: 5px;
}

.block-selfservice-bills table tbody tr:hover {
    color: white !important;
    background-color: rgb(216, 11, 140);
    text-decoration: none;
    cursor: pointer;
}

.block-selfservice-bills table tbody tr:hover .glyphicon {
    color: white !important;
}

.prof-menu a,
.prof-menu a:active,
.prof-menu a:visited {
    color: white;
}

.more-inf {
    background: $color-brand;
    font-size: 14px;
    letter-spacing: normal;
    margin-top: 4px;
    padding: 10px;
}

.more-inf a {
    color: #fff;
}

.mt10 {
    margin-top: 10px !important;
}

.disp-nme {
    border-bottom: 2px solid $color-brand;
    color: $color-brand;
    display: inline-block;
    font-size: 20px;
    margin-top: 38px;
    padding: 3px 0px;
    text-transform: uppercase;
}

.disp-nme:hover {
    color: $color-brand;
    text-decoration: none;
}

a.cta {
    margin-bottom: 5px;
    background: url('/Images/arrow-dark-pink-right.png') no-repeat right center scroll transparent;
    padding-right: 25px;

    .o-full-width-container--gradient &,
    .o-full-width-container--blue-gradient &,
    .o-full-width-container--blue & {
      background: url(/Images/arrow-white-right.png) no-repeat 100% scroll transparent;
    }
}

a.cta:hover {
    text-decoration: none;
}



@media screen and (max-width: 992px) {
    .disp-nme {
        border-bottom: 2px solid $color-brand;
        color: $color-brand;
        display: inline-block;
        font-size: 14px;
        margin-top: 42px;
        max-width: 100%;
        overflow: hidden;
        padding: 3px 0;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;
    }
}

.epi-logo {
    box-sizing: content-box;
}

.epi-navigation--fixed-adjust {
    z-index: auto !important;
}

.trustpilot-widget-carousel{
    padding:40px;
    padding-left:15px;
    padding-right:15px;
    margin-left:auto;
    margin-right:auto;
}

.trustpilot-widget-carousel-small {
    display: none;
}

.trustpilot-widget-carousel-large {
    display: block;
}
@media screen and (max-width: 600px) {
    .trustpilot-widget-carousel {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .trustpilot-widget-carousel-small {
        display:block;
    }
    .trustpilot-widget-carousel-large {
        display:none;
    }
}

@media (min-width: 1200px) {
    .trustpilot-widget-carousel {
        max-width:1170px;
    }
}

.iframe-container{
    position:relative;
}

.cookieKnapContainer{
    text-align:center;
    padding:10px;
}
.cookieKnapContainer  div{
    display:inline-block;
}
.cookieTextContainer a:hover{
    cursor:pointer;
}

.fiber-flow-form .wrapper{
  max-width: 750px;

  .fiber-flow-form__large-input,
  .fiber-flow-form__large-input select {
    max-width: 460px;
  }
}

.coi-banner__header img {
    display: none;
}
