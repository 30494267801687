.linksbanner-block {
  $parent: &;

  position: relative;
  color: $text-color;
  padding: 24px 0;
  text-align: center;
  background-color: $color-lightgrey;

  @media (min-width: $screen-sm-min) {
    padding: 64px 0;
  }

  //if block is positioned inside another container, we still want to force fullwidth background color
  .container & {
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      height: 100%;
      left: -100%;
      right: -100%;

      background-color: $color-lightgrey;
    }
  }

  &--theme-dark {
    background-color: $text-color;
    color:white;

    .container & {
      &:before, &:after {
        background-color: $text-color;
      }
    }
  }

  &--theme-brandcolor {
    @include themeBaggroundGradient;
    color: white;

    .container & {
      &:before {
        @include themeBaggroundGradient;

        content: " ";
        position: absolute;
        top: 0;
        height: 100%;
        left: -100%;
        right: -100%;
      }
    }
  }


  &__headline {
    position: relative;
    margin-top: 16px;

    #{$parent}--theme-dark &, #{$parent}--theme-brandcolor & {
      color: white;
    }
  }

  &__links {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  &__item {
    display: inline-block;
    margin: 15px;
    width: 180px;
    text-align: center;
    flex: 0 1 auto;

    @media (min-width: $screen-md-min) {
      width: 156px;
    }
  }

  &__link {
    color: $text-color;

    #{$parent}--theme-dark &, #{$parent}--theme-brandcolor & {
      color: white;
    }
  }

  &__img {
    width: 60px;
    height: 60px;
    margin: 0 auto 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  //fallback if no img is selected
  &__icon {
    width: 60px;
    height: 60px;
    color: white;
    @include themeBaggroundColor();
    border-radius: 30px;
    padding: 20px 10px;
    margin: 0 auto 10px;

    #{$parent}--theme-brandcolor & {
      @include themeColor();
      background-color: white;
    }

    svg {
      fill: currentColor;
      width: 20px;
      height: 14px;
    }
  }

  &__label {
    word-break: break-word;
    margin: 0;
    @media (min-width: $screen-sm-min) {
      font-size: 20px;
    }
  }
}
