.notes-modal {
    @include themeColor();
    font-size: 11px;
    position: relative;
    top: -2px;
}

.notes-modal:hover {
    @include themeColor();
    text-decoration: none;
}

.modal {
    overflow: hidden;
    background-color:rgba(255,255,255,.9);
}

.modal-content {
    border-radius: 0;
    
    html.ie & {
        box-shadow:none !important;
    }
}

.modal-dialog {
    .modal.fade &,
    .modal & {
        -moz-transition: none !important;
        -o-transition: none !important;
        -webkit-transition: none !important;
        transition: none !important;

        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
    }
}

#pleaseWaitModal {
    z-index: 1100;
}

#windModalNew {
    @media (max-width: 768px) {
        p {
            font-size: 16px;
        }
        .pricing {
            font-size: 20px;
            > span {
                float: left;
            }
            p {
                text-align: right;
            }
        }
        hr {
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .modal-header {
            padding: 8px 15px;
        }
        .modal-content {
            .buttons-div>button {
                font-size: 16px;
                margin-right: 0;
            }
        }
    }
}

#errorModal {    
    .modal-dialog {
        @media (min-width: $screen-sm-min) { width:$container-lg / 2; }
        @media (min-width: $screen-md-min) { width:$container-sm; }
        @media (min-width: $screen-lg-min) { width:$container-md; }
    }
}

.modal-title {
    font-size: 26px;
    line-height: 1.42857;
    margin: 0;
    padding-right:35px;
}

.modal-header {
    border-bottom: 1px solid #e5e5e5;
    color: #fff;
    font-size: 40px !important;
    min-height: 1px;
    padding: 15px;
    position:relative;
    @include themeBaggroundColor();
}


.modal-header .close {
    outline:0;
    margin-top:0;
    padding:0;
    position:absolute;
    right:0;
    top:0;
    border:0;
    background:none;
    background:url('/Images/mob-menu-close.png') no-repeat center center;
    width:65px; /*35px if img width + 2x 15px for padding*/
    height:100%;
}

#surveyModal {
    .modal-dialog {
        height:1000px;
        @media (min-width: $screen-sm-min) { width:$container-lg / 2; }
        @media (min-width: $screen-md-min) { width:$container-sm; }
        @media (min-width: $screen-lg-min) { width:$container-md; }
    }
}

#modal--gdpr, #operationalInfo {
    .modal-dialog {
        display: flex;
        align-items: center;
        height: 100%;

        @media (min-width: $screen-sm-min) {
            margin: 0 auto;
        }

        @media (min-width: $screen-md-min) {
            width: 784px;
        }

        .modal-content {
            box-shadow: none;
            border: none;
            background-color: $color-lightgrey;
            padding: 40px 20px;
            text-align: center;
            width: 100%;
            flex: 0 1 auto;
            max-height: 80vh;
            overflow-y: auto;

            @media (min-width: $screen-sm-min) {
                padding: 50px 80px;
            }

            h2 {
                margin-top: 5px;
                margin-bottom: 10px;

                @media (min-width: $screen-md-min) {
                    margin-top: 20px;
                    margin-bottom: 25px;
                }
            }

            p {
                margin-bottom: 15px;

                @media (min-width: $screen-md-min) {
                    margin-bottom: 25px;
                }

                + .btn__block {
                    margin-top: 25px;
                    @media (min-width: $screen-md-min) {
                        margin-top: 50px;
                    }
                }
            }

            .operational-info__button {
                position: relative;
                border: none;
                color: $text-color;
                background-color: white;
                width: 70%;
                text-align: center;
                padding: 15px;
                font-size: 20px;
                margin-top: 20px;

                span {
                    position: absolute;
                    right: 15px;

                    svg {
                        fill: currentColor;
                        height: 30px;
                        width: 38px;
                        overflow: visible;
                        transform: translateX(-38px);
                        transition: transform .5s cubic-bezier(.645,.045,.355,1);
                    }
                }

                &[type=submit] {
                    color:white;
                    @include themeBaggroundColor();
                }

                &:hover {
                    span svg {
                        transform: translateX(0);
                    }
                }
            }
        }
    }
}

.close-window {
    position:relative;
    color: white;
    min-height: 30px;
    width: 100%;
    padding: 10px;

    @include themeBaggroundColor();
}
