﻿body.operation {
    margin-bottom: 80px;
}

.operation .event {
    background: #18295d;
    color: #fff;
}

.operation-table {
    width: 100%;
    border: 1px solid #525e66;
    padding: 7px;
}

.operation-table td, 
.operation-table th {
    text-align: left;
    vertical-align: top;
    padding: 7px;
}

.operation-table th {
    background: #ddd;
}

.operation-table tr:first-child {
    border-bottom: 3px solid #525e66;
}

.operation-table tr {
    border-bottom: 1px solid #525e66;
}

.operation-table tr:last-child {
    border-bottom: 0;
}

.operation .btn-primary-spl {
    background: $color-turquise;
}

.operation a,
.operation .btn-link {
    color: $color-turquise;
}