﻿.disp-table {
    display: table;
    vertical-align: top;

    .disp-table-cell {
        display: table-cell;
        float: none;
        vertical-align: top;
    }
}

.table--grey {
    width:100%;
    background: #EEEEEE;
    color: $text-color;
    margin: 25px 0;

    th, td {
        border: 1px solid white;
        line-height: 1.2;
        padding: 20px;
    }

    th {
        font-size: 20px;
    }

    td {
        font-size: 16px;
    }
}


@media only screen and (max-width: 767px) {

    .table--grey {
        /* Force table to not be like tables anymore */
        display: block;

        thead, tbody, th, td, tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        td {
            /* Behave  like a "row" */
            border: none;
            position: relative;
        }

        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
        }
    }
}

.table--accordion {
    table {
        margin: 20px 0;
        &.collapsed {
            display: block;
            margin:0;
            tbody {
                height: 65px;
                overflow: hidden;
                display: block;

                > tr {
                    display: block;
                    > th {
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            .table--accordion__trigger {
                &:hover {
                    background-color: rgba(0,0,0,.05);
                }
                .icon-accordion {
                    transform: rotate(0deg);
                }
            }
        }

        tbody > tr > th {
            position:relative;
        }

        .table--accordion__trigger {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 65px;
            padding: 0;
            border: none;
            background: transparent;
            text-align: right;
            padding-right: 20px;
            outline: none;
            transition: background .3s ease-in-out;

            .icon-accordion {
                display: inline-block;
                transform: rotate(45deg);
                transition: transform 0.3s ease-out;
                svg {
                    width:17px;
                    height:17px;
                    fill:currentColor;
                }
            }
        }


    }
}