.flytteportal-entries {
  margin-bottom: 50px;
  &__text {
    padding: 30px 0;

    @media (min-width: $screen-sm-min) {
      padding: 30px 0 10px;
    }

    h2 {
      font-size: 36px;

      @media (min-width: $screen-sm-min){
        font-size: 42px;
        margin: 0 0 20px;
        font-weight: normal;
      }
    }
  }
  &__buttons {
    display: flex;
    flex-flow: column nowrap;
    margin-left: -15px;
    margin-right: -15px;

    @media (min-width: $screen-sm-min) {
      flex-flow: row-reverse nowrap;
      margin: 0;

      > a:first-child {
        margin-left: 30px;
      }
    }
  }
  &__button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1 1 auto;
    background-color: white;
    @include themeColor();
    padding: 12px 15px;
    font-size: 17px;

    > div {
      width: 100%;
    }

    @media (min-width: $screen-sm-min){
      background-color: $color-lightgrey;
      padding: 20px 30px;
      flex: 1 1 35%;
      margin-top: 30px;
      font-size: 18px;
    }

    svg {
      fill:$text-color;
      width: 36px;
      height: 27px;
      margin-left: 50px;
      flex-shrink: 0;
      transition: transform .3s ease-out;
    }

    &--highlighted {
      @include themeBaggroundColor();
      flex: 1 1 65%;
      color:white !important;
      padding: 30px 15px;
      margin-bottom: 20px;

      @media (min-width: $screen-sm-min){
        margin-bottom: 0;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
      }

      h3 {
        font-size: 22px;
        color:white;
        margin-top: 0;
        @media (min-width: $screen-sm-min){
          font-size: 35px;
        }
      }

      p {
        color:white;
        font-size: 16px;
        @media (min-width: $screen-sm-min){
          font-size: 18px;
        }
      }

      svg {
        fill:currentColor;
      }

      @media (min-width: $screen-sm-min){
        padding: 40px 60px;
      }
    }

    &:hover, &:active {
      text-decoration: none;
      svg {
        transform: translateX(10px);
      }
    }
  }
}