﻿.module h2.white-fnt {
    color: #fff;
}

.module.pink-box-mod {
    @include themeBaggroundGradient;

    padding: 80px 0;
}

.module.banner-fan-rt {
    background: center center no-repeat;
    min-height: 430px;
}

.module.banner-fan-lt {
    background: url(/Images/banner-fan-3.jpg) center center no-repeat;
    min-height: 430px;
    padding: 80px 0;
}

.module.banner-fan-rt {
    padding: 0 0 30px;
}

.module .link-list-grey {
    display: inline-block;
    margin-top: 10px;
    color: $text-color;
    font-size: 24px;
}

.module .link-list-wt {
    display: inline-block;
    margin-top: 10px;
    color: #fff;
    font-size: 24px;
}

@media (max-width:769px) {
    .module.banner-fan-rt {
        min-height: 0;
    }
}