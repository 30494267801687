h1, h2, h3 {
  color: $header-color;

  &.white,
  .o-full-width-container--gradient &,
  .o-full-width-container--blue-gradient &,
  .o-full-width-container--blue & {
    color: #fff;
  }
}
