﻿.color-box {
    background-color: $color-brand;
    border-radius: 10px;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 70px;
    text-align: center;

    a {
        width: 100%;
        display: block;
        padding: 30px 20px;
        color: white !important;
        font-size: 24px;

        :visited {            
            color: white !important;
        }
    }

    p {
        margin: 20px 0px 0px;

        img {
            margin-left: 15px;
        }
    }
}