.red-box,
.orange-box,
.pink-box,
.dark-red-box,
.dark-orange-box,
.dark-pink-box,
.dark-grey-box {
    
    &,
    & h1,
    & h2,
    & h3,
    & h4,
    & p {
        color:#fff;
    }

    & a {
        
        &,
        &:visited,
        &:focus,
        &:hover {
            color:#fff;
        }

        &.cta {
            background-image:url('/Images/arrow-white-right.png');
        }
    }
}

.pink-bg {
    @include themeBaggroundColor();
}

.red-box {
    background: url('/Images/red-plus.png') left bottom repeat-x #eb403c;
}

.dark-red-box {
    background: $color-brand;
    padding: 80px 0;
}

.orange-box {
    background: url('/Images/red-plus.png') left bottom repeat-x $color-brand;
    padding: 80px 0 0;

    .email-txt {
        margin-bottom: 90px;
    }

    .label-orng {
        background: #fff;
        color: $color-brand;
        margin-bottom: 80px;
    }
}

.dark-orange-box {
    background: $color-brand;
    padding: 80px 0;
}

.pink-box {
    background: url(/Images/red-plus.png) left bottom repeat-x $color-brand;
}

.pink-box-inner {
    @include themeBaggroundColor();
    padding: 130px 0 160px;
    position: relative;
}

.dark-pink-box {
    @include themeBaggroundColor();
    padding: 80px 0;
}

.over-hide {
    overflow: hidden;
}

.company-logo {
    margin: 40px 0 0;
    text-align: center;
}

.title-img-orang {
    color: $color-brand;
    font-size: 22px;
    margin-bottom: 0;
}

.learn-more-pink {
    color: #ef413d;
    font-size: 24px;
    margin: 40px 0;
    display: block;

    &:hover {
        text-decoration: none;
        color: #ef413d;
    }

    img {
        margin-left: 20px;
    }
}

.grey-box {
    background: #f1f2f2;
}

.grey-2-bg {
    background: #edefef;
}

.dark-grey-box {
    @include themeBaggroundGradient;

    padding: 75px 0;
}

.light-grey {
    background: $color-lightgrey;
    padding-top: 80px;
}

.pink-bold {
    color: #ef413d;
    font-weight: 600;
}

.telephone {
    color: #fff;
    font-size: 72px;
    margin-top: 18px;
}

/* MARGINS AND PADDINGS */
.mtb80 {
    margin: 80px 0;
}

.mtb30 {
    margin: 30px 0;
}

.mtb46 {
    margin: 74px 0 46px;
}

.mt46 {
    margin-top: 46px;
}

.mt22 {
    margin-top: 22px !important;
}

.mt20 {
    margin-top: 20px;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mb60 {
    margin-bottom: 60px;
}

.mbn70 {
    margin-bottom: -70px;
}

.mr20 {
    margin-right: 20px;
}

.mr16 {
    margin-right: 16px;
}

.no-margin {
    margin: 0;
}

.pb40 {
    padding-bottom: 40px;
}

.pb30 {
    padding-bottom:30px;
}

.pt10 {
    padding-top: 10px;
}

.pt80 {
    padding-top: 80px;
}

.pt40 {
    padding-top: 40px;
}

.pt30 {
    padding-top: 30px;
}

.ml10 {
    margin-left: 10px;
}

.ml32 {
  margin-left: 32px;
}

.ml40 {
    margin-left: 40px !important;
}

.nb {
    border: none !important;
}

.mb46 {
    margin-bottom: 46px;
}

.mb80 {
    margin-bottom: 80px;
}

.mt140 {
    margin-top: 140px;
}

.mt90 {
    margin-top: 90px;
}

.mtb60 {
    margin: 60px 0 30px;
}

.pb80 {
    padding-bottom: 80px;
}

.nmt {
    margin-top: 0 !important;
}

.nmb {
    margin-bottom: 0;
}

.npb {
    padding-bottom: 0 !important;
}

.ml20 {
    margin-left: 20px;
}

.font-22 {
    font-size: 22px;
}

.remove-fat {
    font-weight: normal !important;
}

.cloud-icon {
    margin: 60px 0 40px;
}

.mobile-icon {
    margin: 22px 0 0;
}

.icon-image {
    margin: 0 20px 0 0;
}

.steps {
    margin: 40px 0 20px;
}

.ipad-bg {
    height: 856px;
    background: url(/Images/section-1.jpg) center center no-repeat;
    margin-top: 20px;
}

.ipad-bg-sm {
    height: 604px;
    background: url(/Images/store-section-1.jpg) center center no-repeat;
    margin-top: 90px;
}

.store-sec-2,
.store-sec-3 {
    height: 582px;
    background: url(/Images/store-section-2.jpg) center center no-repeat;
    margin-top: 90px;
}

.install-sec-1 {
    height: 582px;
    background: url(/Images/installation-section-1.jpg) center center no-repeat;
    margin-top: 90px;
}

.wahoo {
    margin-top: 0;
}

.section-2 {
    background: url(/Images/section-2.jpg) center center no-repeat;
    height: 678px;
}

.inner-grey {
    background: #edefef;
    padding-bottom: 76px;

    &.mt46 {
        margin-top: 46px;
    }
}

.sea-bg {
    height: 856px;
    background: url(/Images/banner-advice-2.jpg) center center no-repeat;
}

.icon-holder {
    float: left;
    padding: 0 20px 40px 0;
    text-align: right;
    width: 114px;
}

.big-number {
    @include themeColor();
    font-size: 80px;
    line-height: 52px;
}

.grey-box-1 {
    background: #d4d7d9;
    padding-bottom: 70px;
}

.grey-box-2 {
    background: #cbced1;
    padding-bottom: 70px;
}

.tab_icon {
    display: block;
}

.greyscaleHolder {
    text-align: center;

    a {
        display: inline-block;
        margin-bottom: 44px;
        padding-bottom: 66px;
        outline: 0;

        &.active {
            background: url('/Images/orng-arr-dn-lg.png') bottom center no-repeat;

            &.active:active,
            &.active:focus {
                outline: 0;
            }

            span {
                border: 4px solid $color-brand;
            }

            .colourOns {
                opacity: 1 !important;
            }
        }

        span {
            width: 100%;
            display: inline-block;
            position: relative;
        }
    }

    & > span {
        display: inline-block;
        position: relative;
    }
}

.learn-more {
    color: $text-color;
    background: url('/Images/grey-dwn.png') right center no-repeat;
    padding-right: 30px;
    margin: 40px 0;
    display: inline-block;

    &.active {
        background: url('/Images/grey-up.png') right center no-repeat;
    }

    &:active, &:focus, &:hover {
        color: $text-color;
    }
}

.colourOns {
    left: 0;
    position: absolute;
    opacity: 0;
    top: 0;
}

.soft-hidden {
    display: none;
}

.bt1 {
    border-top: 1px solid #fff;
}

.border-left {
    border-left: 1px solid #525e66;
}

.vbottom-xs {
    vertical-align: bottom;
    display: inline-block;
    float: none;
}

.valign-middle {
    vertical-align: middle !important;
}

.large-icon-holder {
    padding-right: 30px;
    min-width: 180px;
}

.col-20p {
    width: 20%;
    float: left;
    min-height: 1px;

    .footer-sub > li {
        line-height: 20px;
    }
}

.clear-xs {
    display: none;
}

.lh-40 {
    line-height: 40px;
}

.arrow-icon {
    display: inline-block;
    height: 20px;
    margin-left: 18px;
    vertical-align: bottom;
    width: 20px;

    .privat & {
        background: url(/Images/arr-sprite-pink.png) center 0 no-repeat;
    }

    .collapsed & {
        background-position: center -20px;
    }
}

.kampagne-section-1, .kampagne-section-2, .kampagne-section-3 {
    background: $color-brand;
    padding: 0 0 60px;
}

.btw {
    border-top: 1px solid #fff;
}

.block-item {
    display: block;
}

.section-selection {
    margin-top: 20px;
    margin-left: 51px;
    @media (min-width: $screen-sm-min){
        margin-left: 5px;
    }
    @media (min-width: $screen-lg-min){
        margin-left: 19px;
    }
    height: 80px;

    &--user-logged-in {
        margin-left: 5px;
        @media (min-width: $screen-lg-min){
            margin-left: 19px;
        }
    }

    a {
        font-size: 22px;
        line-height: 1.2;
        color: $text-color;
        display: inline-block;
        margin: 23px 0 0 30px;

        &:first-child {
            margin-left:0;
        }

        &.active {
            @include themeColor();
        }
    }
}

// privat/erhverv menu when menu is open
.section-selection-hover {
    float: left;
    height: 55px;
    margin-left: 55px;

    a {
        color: white;
        display: inline-block;
        margin: 16px 0 0 30px;
        font-size: 22px;
        line-height: 1.2;

        &:hover, &:focus {
            color: #fff;
            text-decoration: underline;
        }
        &:active,&.active {
            color: #fff;
        }
    }

    @media(max-width:$screen-md){
        height: auto;
        margin-left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: calc(100% - 50px);
        a {
            padding: 15px;
            margin:0 15px 0 -15px;
            color:white !important;

            &.active {
                background:#4E5961;
                @include themeColor();
            }
        }
    }
}

.arrow-rt-pvt {
    background: url(/Images/arrow-right-privat.png) left 8px no-repeat;
    display: block;
    padding-left: 10px;
    color: $text-color;

    &:active,
    &:focus,
    &:hover {
        color: $text-color;
        text-decoration: none;
    }
}

.arrow-pvt-lg {
    font-size: 34px;
    color: $color-brand;
    display: inline-block;
    margin-top: 30px;

    &:active,
    &:focus,
    &:hover {
        color: $color-brand;
    }
}

.dark-title {
    color: $color-brand;
}

.orange-box-module {
    background: url(/Images/red-plus.png) left bottom repeat-x $color-brand;
    min-height: 530px;
    padding: 80px 0 0;
    position: relative;
    margin-top: 180px;

    .telephone {
        font-size: 60px;
    }
}

.man-1 {
    position: absolute;
    right: 0;
    bottom: -60px;
}

.pink-box-inner-2 {
    background: $color-brand;
    padding: 60px 0;
    position: relative;
    margin-top: 200px;
}

.p-relative {
    position: relative;
}

.pos-relative {
    position: relative;
}

.b-96 {
    bottom: -96px;
}

.calandar {
    padding-top: 10px;
    display: inline-block;
}

.timing span {
    display: inline-block;
    min-width: 100px;
}


.notify-arrow {
    background: url(/Images/menu-arrow-dn.png) no-repeat;
    width: 34px;
    height: 15px;
    display: inline-block;
    float: right;
    margin-top: 16px;

    .active & {
        background: url(/Images/menu-arrow-up.png) no-repeat;
    }
}

.text-center-xs {
    @media (min-width: $screen-phone) {
		text-align: center;
	}
    @media (min-width: $screen-tablet) {
		text-align: inherit;
	}
}

.text--brandcolor {
    color:$color-brand;

    .theme-yourelectricity & {
        color: $color-turquise;
    }
}

.label--block {
    display: block;
    clear: both;
}
