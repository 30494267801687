// Media query manager.
// @param {String} $breakpoint - Breakpoint
// @requires $breakpoints variable
// Example: @include mq(sm){}
@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  @else {
    @warn 'No value found for `#{$breakpoint}`. Please make sure it is defined in the `$breakpoints` map.';
  }
}
