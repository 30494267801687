.faktabox {
    width: 100%;
    margin: 0 25px 0 25px;
    color: white;
    padding: 25px;
    background-color: $color-brand-light;

    @media (min-width: $screen-sm-min) {
        width: 50%;
    }

    @media (min-width: $screen-md-min) {
        width: 30%;
        margin: 0 25px 25px 25px;
    }

    h3.faktabox__title {
        margin-top: 0;
        color: white!important;
        margin-bottom: 10px;
    }

    ul {
        padding-left: 18px;
    }

    //note that this also affects auto-interpreted links os ios
    a {
        color:white !important;
        text-decoration: none !important;
        border-bottom: none !important;
    }

    &--left {
        float: left;
        margin-left: 0;
    }

    &--right {
        float: right;
        margin-right: 0;
    }

    &--center {
        margin-left: auto;
        margin-right: auto;
    }

    &--grey {
        background-color: #808080 !important;
    }

    .theme-yourelectricity & {
        background-color: $color-turquise;
    }
}