﻿/* 🎨 Brand */
$color-brand:       #D90078;
$color-brand-light: #FF0097;

/* 🎨 Sub brand */
$color-turquise:    #16A09C;

/* 🎨 Secondary colors */
$color-blue:        #0083FE;
$color-dark-blue:   #0074E0;
$color-orange:      #FE6A00;

/* 🎨 Gradients colors */
$color-brand-gradient-1: $color-brand-light;
$color-brand-gradient-2: #FE791B;

$color-darkest-blue:#18295d;
$color-lightgrey: #eee;
$color-error: #FF2520;
$color-alert: $color-error;
$color-green:#3F9C35;

$text-color: #4E5961 !default;
$header-color: #17191A;
$link-color: $color-brand !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-color--secondary: #525e66 !default;
$link-hover-color--secondary: lighten(#525e66, 10%) !default;
$link-visited-color: darken($text-color, 25%) !default;

$menuBg: #4E5961;
$info-box-bg: $color-lightgrey;
