//old block element
.sub-nav-item {
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    text-decoration: none;
    display: table;
    color:$text-color;

    img,
    span {
      display:table-cell;
      vertical-align:middle;
    }

    span {
      padding-left:5px;
    }

    @media screen and (min-width: $screen-md-min) {
      &:focus,
      &:hover{
        text-decoration:none;
        color:$text-color;
      }
    }

    svg,
    svg path {
      width:59px;
      height:59px;
      fill: $link-color;

      .theme-yourelectricity & {
        fill: $color-turquise;
      }
    }
  }
}

//new block element
.dynamic-sub-nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .sub-nav-item {
    margin-top:20px;
    margin-bottom:20px;
    width:100%;
    flex: 0 1 auto;

    a {
      text-decoration:none;
      display:flex;
      align-items: center;
      color:$text-color;
      width: 100%;

      &:focus,
      &:hover {
        color:$text-color;
      }

      img,
      span {
        display:inline-block;
        align-self: center;
      }

      p {
        margin: 0 10px;
      }

      span {
        width:100%;
        flex: 1;
        margin-right: 10px;
      }

      img {
        width:59px;
        flex: 0 1 auto;
        align-self: center;
      }

      svg,
      svg path {
        width:59px;
        height:59px;
        fill: $link-color;

        .theme-yourelectricity & {
          fill: $color-turquise;
        }
      }
    }
  }
}