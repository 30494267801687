.rep-person {
	margin-bottom: 30px;
	background: #eee;
	font-size: 16px;
	overflow: hidden;

	//to items side by side in medium screensizes
	@media (min-width: 600px) and (max-width: $screen-sm-max){
		margin: 0 10px 30px;
		width: calc(50% - 20px);
		float: left;
	}

	figure {
		img {
			width: 100%;
			vertical-align: top;
		}
	}

	&__info {
		min-height: 160px;
		padding: 15px 20px 10px;

		display: flex;
		flex-flow: column nowrap;
	}

	&__name {
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 0 1 auto;
	}

	&__position {
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 0 1 auto;
	}

	&__spacer {
		flex: 1 1 auto;
	}

	&__contact {
		flex: 0 1 auto;
	}

	&__email {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		a {
			&,
			.content-links-text & {
				border: 0;
			}
		}
	}

	&__phone {
		white-space: nowrap;
	}

	&__description {
		border-bottom: 1px solid #d4d7d9;

		&-trigger {
			position: relative;
			border-top: 1px solid #d4d7d9;
			padding: 5px 40px 5px 20px;
			cursor: pointer;
			transition: background .3s ease-out;

			@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
				padding: 5px 25px 5px 10px;
			}

			&:hover {
				background: #d4d7d9;
				transition-duration: .15s;
			}

			&.deactivated {
				cursor: default;

				.svg {
					display:  none;
				}
			}

			&.active {
				color: #fff;
				@include themeBaggroundColor();
				.svg {
					transform: rotate(45deg);

					svg {
						fill: #fff;
					}
				}
			}

			.svg {
				position: absolute;
				top: 7px;
				right: 20px;
				width: 17px;
				height: 17px;
				transition: transform .3s ease-out;

				@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
					top: 10px;
					right: 10px;
					width: 11px;
					height: 11px;
				}

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		&-target {
			max-height: 0;
			overflow: hidden;
			transition: max-height .5s ease-out;

			> div {
				padding: 15px 20px;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 14px;

				@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
					padding: 5px 10px;
				}
			}
		}
	}
}
