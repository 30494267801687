.advantages-block {
  position: relative;
  color: $text-color;
  padding: 40px 0;
  background-color: $color-lightgrey;

  @media (min-width: $screen-sm-min) {
    padding: 80px 0;
  }

  //if block is positioned inside another container, we still want to force fullwidth background color
  .container & {
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      height: 100%;
      left: -100%;
      right: -100%;

      background-color: $color-lightgrey;
    }
  }

  &--theme-dark {
    background-color: $text-color;
    color:white;

    .container & {
      &:before, &:after {
        background-color: $text-color;
      }
    }
  }

  &--theme-brandcolor {
    @include themeBaggroundGradient;
    color: white;

    .container & {
      &:before {
        @include themeBaggroundGradient;

        content: " ";
        position: absolute;
        top: 0;
        height: 100%;
        left: -100%;
        right: -100%;
      }
    }
  }

  &__flex {
    position: relative;
    @media (min-width: $screen-sm-min) {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      > * {
        flex: 0 1 50%;
      }
    }
  }

  &__headline {
    position: relative;
    color: inherit;
    margin: 0;

    @media (min-width: $screen-sm-min) {
      width: 50%;
      padding-right: 15px;
    }
  }

  &__list {
    color: inherit;
    list-style: none;
    margin: 32px 0 0;
    padding: 0;
    font-size: 17px;
    line-height: 24px;

    @media (min-width: $screen-sm-min) {
      .advantages-block__flex & {
        margin-top: 0;
      }
    }

    > li {
      position: relative;
      margin: 32px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: $screen-sm-min) {
      width: 50%;
      padding-right: 15px;
    }
  }

  &__text {
    display: inline-block;
    padding-left: 38px;
  }

  &__icon {
    position: absolute;

    svg {
      fill: currentColor;
      width: 20px;
      height: 14px;
    }
  }

  &__image {
    @media (min-width: $screen-sm-min) {
      &-container {
        display: block;
        height: 100%;
        width: 50%;
        position: absolute;
        top: 0;
        left: 50%;

        .container & {
          width: 50vw;
          z-index: 1;
        }
      }
    }
  }
}