.row--flex {
  margin: 15px -15px;
  > div {
    margin: 15px;
  }

  @media (min-width: $screen-md-min) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    clear:both;

    > div {
      flex: 1;
      align-self: stretch;
    }
  }
}

// Specific layout for FAQ page...
.row--faq-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 1rem 0;

  @media (min-width: $screen-sm-min) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: $screen-md-min) {
    grid-template-columns: repeat(6, 1fr);
  }

  &::before, &::after {
    display: none;
  }

  // Override default styles for content-box and always show image/icon
  .content-box {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    min-height: 48vw; // Prevent boxes from jumping when loading

    @media (min-width: $screen-xs-min) {
      min-height: 38vw;
    }
    @media (min-width: $screen-sm-min) {
      min-height: 28vw;
    }
    @media (min-width: $screen-md-min) {
      min-height: 200px;
    }

    > img {
      display: block;
    }

    .content-box__text {
      margin-top: auto;
    }
  }
}