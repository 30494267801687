﻿.event {
    padding: 10px;
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $color-brand;

    .theme-yourelectricity & {
        background-color: $color-turquise;
    }
}
