﻿.prettyradio a {
    background: url(/Images/check-radio.png) no-repeat;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    height: 26px;
    margin: 0;
    width: 26px;
}

.prettyradio a.error {
    background: url(/Images/check-radio-error.png) no-repeat;
}

.prettyradio a.error.checked {
    background: url(/Images/checked-radio.png) no-repeat;
}

.prettycheckbox .checked {
    background: url(/Images/checked-box.png) no-repeat;
}

.prettyradio .checked {
    background: url(/Images/checked-radio.png) no-repeat;
}