.dynamic-content-container {
    position:relative;
    padding: 70px 0 40px 0;

    &.grey-bg {
        background-color: #eee;
    }

    &.white-bg {
        background-color: white;
    }

    /* Handling when it exists inside a container, thus narrowing the edge-to-edge width */
    .container & {
        margin-bottom:30px;

        &:before {
            content:" ";
            position:absolute;
            width:100%;
            height:100%;
            left:-100%;
            top:0;
        }
        &:after {
            content:" ";
            position:absolute;
            width:100%;
            height:100%;
            left:100%;
            top:0;
        }

        &.grey-bg:before,
        &.grey-bg:after {
            background-color: #eee;
        }
        &.white-bg:before,
        &.white-bg:after {
            background-color: #fff;
        }
    }

  .dynamic-box {
    margin-bottom: 32px;
  }
}

// Hack
.dynamic-content-container > .container >.row >.dynamic-box {
  padding-left: 1rem;
  padding-right: 1rem;
}
.dynamic-content-container > .container >.row >.dynamic-box .dynamic-box {
  padding-left: 1rem;
  padding-right: 1rem;
}
