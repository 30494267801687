﻿.white-link {
    color: #fff;
    display: block;
    margin-top: 29px;
    font-size: 24px;

    .icon-arrow-rt {
        background: url('/Images/arr-rt-wt.png') right top no-repeat;
        display: inline-block;
        height: 30px;
        margin-bottom: -12px;
        width: 30px;
    }

    &:hover {
        color: #fff;
        text-decoration: none;
    }

    &.small {
        font-size: 20px;
    }
}

.orange-link,  {
    font-size: 24px;
    color: $color-brand;
    margin-top: 50px;
    display: block;

    &:hover {
        text-decoration: none;
        color: $color-brand;
    }
}

.pink-link {
    color: $color-brand;
    display: block;
    font-size: 26px;

    &:hover {
        color: $color-brand;
        text-decoration: none;
    }
}

.pink-link-inline {
    color: $color-brand;

    &:hover {
        text-decoration: none;
        color: $color-brand;
    }
}

.privat-link {
    width: 100px;
    height: 100px;
    display: block;
    text-align: center;
    background: url(/Images/pink-button.png) no-repeat;
    color: #fff;
    padding-top: 30px;
    margin-bottom: 20px;
    margin-top: 100px;
    outline: 0;

    &.mt36 {
        margin-top: 36px;
    }

    &:active,
    &:focus,
    &:hover {
        color: #fff;
        text-decoration: none;
        outline: 0;
    }
}

.business-link {
    width: 100px;
    height: 100px;
    display: block;
    text-align: center;
    background: url('/Images/orng-button.png') no-repeat;
    color: #fff;
    padding-top: 30px;
    outline: 0;

    &:active,
    &:focus,
    &:hover {
        color: #fff;
        outline: 0;
        text-decoration: none;
    }
}


.hero-link {
    width: 118px;
    height: 118px;
    display: block;
    text-align: center;
    background: url('/Images/rounded-bg-1.png') no-repeat;
    color: #fff;
    padding-top: 30px;
    margin-bottom: 20px;
    margin-top: 100px;
    outline: 0;

    &.second-link {
        margin-top: 0;
    }

    &:active,
    &:focus,
    &:hover {
        color: #fff;
        text-decoration: none;
        outline: 0;
    }
}

.hero-link-2 {
    width: 100px;
    height: 100px;
    display: block;
    text-align: center;
    background: url(/Images/rounded-bg-2.png) no-repeat;
    color: #fff;
    padding-top: 30px;
    margin-bottom: 20px;
    margin-top: 100px;
    outline: 0;
    font-size: 16px;

    &.second-link {
        margin-top: 0;
    }

    &:active,
    &:focus,
    &:hover {
        color: #fff;
        text-decoration: none;
        outline: 0;
    }
}

.orange-link-inline {
    color: $color-brand;

    &:hover {
        color: $color-brand;
        text-decoration: none;
    }
}

.dark-link {
    color: $color-brand;
    font-size: 26px;
    display: block;

    &:hover {
        color: $color-brand;
        text-decoration: none;
    }
}

.dark-link-inline {
    color: $color-brand;

    &:hover {
        color: $color-brand;
        text-decoration: underline;
    }
}

.grey-link {
    margin: 40px 0 0;

    &:hover {
        color: $text-color;
    }
}

.grey-link-icon {
    color: $text-color;

    &:hover {
        color: $text-color;
        text-decoration: none;
    }
}

.white-arrow-link {
    background:url('/Images/arr-rt-wt-sm.png') no-repeat right center;
    padding-right:30px;
}