.rte-block:not(.custom-form) {
  h2 {
    color: $header-color;

    &:first-child {
      margin-top: 0;
    }
  }

  h3,h4,h5,h6 {
    color: #17191A;

    &:first-child {
      margin-top: 0;
    }
  }

  a:not(.cta) {
    text-decoration: underline;
    @include themeColor();

    &:visited {
      @include themeDarkenColor();
    }
  }
}
