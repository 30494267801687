﻿.jumbotron_white h1 {
    color: #fff;
}

.jumbotron_grey h1 {
    color: $text-color;
}

.jumbotron_white,
.jumbotron_grey {

    h1 {
        margin-top: 30px;
        font-size:33px;
    }

    a {
        font-size:30px;
    }
}

@media screen and (min-width:$screen-sm-min) {
    
    .jumbotron_white,
    .jumbotron_grey {

        h1 {
            margin-top: 104px;
            font-size:36px;
        }

        a {
            font-size:34px;
        }

    }
}