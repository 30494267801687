.image-explanation {
  position: relative;
  &__pin {
    position: absolute;
    width:27px;
    height: 22px;
    background: none;
    border: none;
    -webkit-appearance: initial;
    padding: 0;
    transform: translate3d(-100%,-50%,0);

    span {
      position: absolute;
      text-align: center;
      top: 3px;
      width: 100%;
      color: white;
      font-size: 12px;
      margin-left: -2px;
    }

    svg {
      width:100%;
      height: 100%;
      transition: fill .3s ease-out;
      @include themeFill();
    }

    &:focus, &:hover {
      svg {
        @include themeDarkenFill();
      }
    }
  }

  &__list {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__info {
    position: relative;
    @media(max-width: 767px) {
      top: 0 !important;
    }
    background-color: #eee;
    border-top: 1px solid white;
    width: 100%;
    padding: 30px 50px 20px 60px;
    margin: 0;
    font-size: 18px;

    @media(min-width: $screen-sm-min){
      position: absolute;
      top: 0;
      visibility: hidden;
      opacity: 0;
      transform: translateY(200px);
      padding: 60px 30px 30px 30px;
      transition: all .7s cubic-bezier(0.190, 1.000, 0.220, 1.000); /* easeOutExpo */

      &.active {
        visibility: visible;
        z-index: 1;
        opacity: 1;
        transform: translateY(50px);
      }
    }

    @media(min-width: $screen-md-min){
      padding: 60px 180px 60px 140px;
    }

    h4 {
      text-transform: uppercase;
      margin: 0;
    }
    p {
      color: $text-color;
    }

    .image-explanation__pin {
      position: absolute;
      top: 28px;
      left: 15px;
      @media(min-width: $screen-sm-min){
        top: 35px;
        left: 58px;
      }
      @media(min-width: $screen-md-min){
        top: 68px;
        left: 95px;
      }
    }
  }

  &__close {
    display: none;
    @media(min-width: $screen-sm-min){
      display: block;

      position: absolute;
      width: 22px;
      height: 22px;
      top:30px;
      right: 30px;
      background: none;
      border: none;
      -webkit-appearance: initial;
      padding: 0;

      svg {
        fill:black;
        transition: fill .3s ease-out;
        width: 100%;
        height: 100%;
      }

      &:hover, &:focus {
        svg {
          @include themeFill();
        }
      }
    }
    @media(min-width: $screen-md-min){
      right: 45px;
    }
  }
}