.alert {
  &--major {
    position: relative;
    z-index: 1;
    width: 100%;
    bottom: 0;
    background-color: $color-alert;
    color: white;

    > .container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  &--mobile {
    @media (min-width: $screen-md-min){
      display: none;
    }
  }
  &--desktop {
    z-index: 9;
    display: none;
    @media (min-width: $screen-md-min){
      display: block;
    }
  }
  &__link {
    flex: 1 1 auto;
    color: white;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &:hover, &:active {
      color:white;
      text-decoration: none;
    }

    &:visited {
      color:white;
    }
  }

  &__icon {
    height: 26.4px;
    width: 30px;
    margin-right: 15px;

    svg {
      fill: currentColor;
      width: 100%;
      height: 100%;
      animation: pulse 1s ease-in-out 0s infinite;
    }
  }

  &__title {
    margin-right: 20px;
  }

  &__text {
    display: none;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
    }
  }

  &__close {
    flex: 0 1 auto;
    color: white;
    background: none;
    border: none;
    -webkit-appearance: none;
    width: 26px;
    height: 26px;
    padding: 0;
    margin: 0;

    svg {
      fill: currentColor;
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes pulse {
  0%, 80% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.15);
  }
}