.contentarea {
  &__header {
    padding-top: 3rem;
    margin-bottom: 1rem;

    h1{
      //margin: 0;
    }
    .social-icons {
      margin-top: 10px;
      float:none;

      @media (min-width: $screen-md-min) {
        margin-top: 5px;
        float: right;
      }
    }
  }
  &__body {
    overflow: hidden;
    //needed because of the fixed footer reveal effect
    background-color:white;
    > div > div {
      @include clearfix();
    }
  }

  &__primary {
    > div > div {
      @include clearfix();
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      &.without-margin{
        margin-bottom: 0;
      }
    }
  }

  &__secondary {
    margin-top: 30px;

    @media (min-width: $screen-md-min){
      margin-top: 0;
    }
  }
}