﻿.component.contact {
    background-color: #eee;
    width:100%;
    margin-top:20px;
    margin-bottom:20px;

    h2 {
        font-size: 25px;
        font-weight:600;
        line-height: 1.2;
        margin:0;
        margin-bottom:20px;
    }

    a {
        @include themeColor();
    }

    p {
        margin:0;
        margin-bottom:5px;
    }

    a {
        border-bottom: 0;
    }

    .img-container {
        width:100%;
        
        img {
            width:100%;
        }
    }

    .content {
        padding: 20px;
        font-size: 16px;
        line-height: 1.2;
        color: $text-color;
        margin-bottom: 0;

        &__section + .content__section {
            margin-top: 20px;
        }
    }



    @media (min-width: $screen-xs-min) {
        width:100%;
        margin-top:0;
        margin-bottom:20px;

        .img-container {
            width:auto;
            max-height:300px;
            overflow:hidden;

            img {
                width:auto;
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        width: 260px;
        &--left {
            float: left;
            margin-right: 20px;
        }
        &--right {
            float: right;
            margin-left: 20px;
        }
        &--center {
            margin-left: auto;
            margin-right: auto;
        }

        .contentarea__secondary & {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }
}