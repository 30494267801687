﻿/* GENERAL STYLES */
/* html{
    overflow-y:scroll;    
} */

body {
    position: relative;
    z-index: 1;
    padding-top: 0;
    padding-bottom: 0;

    > div {
        background-color: white;
    }
}


a:focus {
    color: inherit;
    text-decoration: inherit;
}

iframe,
img {
    max-width: 100%;
}

@media screen and (min-width: 992px) {
    iframe {
        max-width: none;
    }
}

.logout-menu-pnt {
    background: $color-brand !important;
}

.disp-ib {
    display: inline-block;
}

img[src*="TrackerHandler.ashx"] {
    display: none;
}

.container .container {
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $screen-sm-min) {
        width: auto;
    }
    @media (min-width: $screen-md-min) {
        width: auto;
    }
    @media (min-width: $screen-lg-min) {
        width: auto;
    }
}

.dynamic-content-container .container .container.module {
  padding-top: 0;
  padding-bottom: 0;
}