#mediaQueryIndicator {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  content: "";
  visibility: hidden;
  opacity: 0;
  clip: rect(0, 0, 0, 0);
  user-select: none;
  pointer-events: none;
  top: -1px;
  display: block !important; // The element is hidden by default by JS to avoid displaying it if there is no associated styling (in case of slow networks or similar)

  @include mq(xs){
    width: 2px;
  }
  @include mq(sm){
    width: 3px;
  }
  @include mq(md){
    width: 4px;
  }
  @include mq(lg){
    width: 5px;
  }
  @include mq(xl){
    width: 6px;
  }
  @include mq(xxl){
    width: 7px;
  }
}