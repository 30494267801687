﻿.bread-crumb {
    padding: 15px 0;
    font-size: 16px;

    .title-txt {
        font-weight: 600;
        padding-right: 10px;
    }

    a {
        color: $text-color;
        background: url(/Images/arrow-breadcrumb.png) right center no-repeat;
        display: inline-block;
        margin-right: 10px;
        padding: 0 18px 0 0;
        text-decoration: underline;
    }

    .navigation-page {
        color: $text-color;
        background: url(/Images/arrow-breadcrumb.png) right center no-repeat;
        display: inline-block;
        margin-right: 10px;
        padding: 0 18px 0 0;
        //text-decoration: underline;
    }

    .active-page {
        @include themeColor();
    }
}
