.toggle-visibility__target {
  display: none;
  opacity: 0;
  animation: fadeIn .5s normal forwards ease-in-out;
}

.toggle-visibility__trigger {
  display: none;

  ~ label {
    display: inline;
  }

  &:checked {
    ~ .toggle-visibility__target {
      display: block;
    }
  }
}