.ui-autocomplete {
  list-style: none;
  font-size: 18px;
  line-height: 1.2;
  padding: 10px 0;
  margin: 0;
  width:100%;
  max-height: 300px;
  overflow: auto;
  background-color: #fff!important;
  box-shadow: 0 2px 10px 0 rgba(#17191A,.05) !important;
  border: 1px solid #E9EBEC;
  border-top: none;
  border-radius: 0 0 $border-radius-base $border-radius-base;

  @media(min-width: $screen-sm-min){
    width: 469px;
  }
  @media(min-width: $screen-md-min){
    width: 696px;
  }
  @media(min-width: $screen-lg-min){
    width: 1120px!important;
  }

  li {
    font-size: 18px;
    padding: 6px 1.5rem;
    color: #17191A;
    border: none!important;
    cursor: pointer;
  }
}

.ui-widget {
  font-family: inherit!important;
}