.nav-tabs {
  border-bottom: none;
  margin-bottom: 2px;
  display: flex;
  flex-flow: row nowrap;


  > li {
    float:none;
    > a {
      font-size: 16px;
      color:$text-color;
      height:100%;
      line-height: 1.2;
      background-color: #eee;
      border: none;
      border-radius: 0;
      margin: 0 1px 0 0;
      padding: 8px 10px;
      @media (min-width: $screen-sm-min) {
        padding: 14px 20px;
        font-size: 18px;
      }
      @media (min-width: $screen-md-min) {
        padding: 18px 20px;
        font-size: 20px;
      }
      &:hover {
        color:white;
        @include themeBaggroundColor();
      }
    }

    &.active {
      > a, a:focus, a:hover {
        color:white;
        @include themeBaggroundColor();
        border:none;
      }
    }
  }
}


.tab-content {
  background-color: #eee;
  padding: 20px;

  &.tab-content--no-padding {
    padding: 0;

    .multi-lvl-accordion {
      background: white;
    }

    .tab-pane > h2 {
      padding: 22px 20px 20px;
      margin: 0;
    }
  }

  .tab-pane > h2 {
    padding: 2px 0 20px 0;
    margin: 0;
  }

  /*.iframe-container,*/.multi-lvl-accordion {
    margin-left: -20px;
    margin-right: -20px;
  }

  .tab-pane {
    position: relative;
    @include clearfix();

    .tab-pane--wrapper {
      padding: 0 0 20px 0;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &__header {
      float: left;
      > h3:first-child {
        margin-top: 2px;
      }
      width: 66%;

      &--radio {
        float:none;
        width: 100%;
      }
    }
    &__right {
      width: 33%;
      float:right;
      text-align: right;
      .price {
        position: relative;
        font-size: 55px;
        margin-bottom: 0;

        span {
          position: absolute;
          font-size: 25px;
          right: 0;
          top: 8px;
        }

        &__label {
          font-size: 16px;
          line-height: 1.2;
        }
      }
    }

    &__footer {
      margin-top: 30px;
      @media (min-width: $screen-sm-min) {
        width: 66%;
        float: left;
      }

      p {
        font-size: 12px !important;
      }
    }

    &__bottom-right {
      margin-top: 30px;
      @media (min-width: $screen-sm-min) {
        float:right;
        width: 33%;
        padding-left: 30px;
      }
    }

    h3 {
      font-size: 42px;
    }

    a {
      border-bottom: none;
    }
  }
}

.package-grid {
  width: 100%;

  display: inline-grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-gap: 1px;
  margin-top: 1rem;

  @media (min-width: $screen-sm-min) {
    grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
  }

  > div:not(.c-modal-dialog),
  .package-grid__button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100px;
    padding: 1rem 0;
    border: none;
    background-color: white;
    outline: none;

    &:not(:disabled) {
      text-decoration: underline;
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      padding: 0 1rem;
      margin-bottom: 0.5rem;
      transition: transform 150ms ease-in;
    }

    p {
      font-size: 12px;
      width: 100%;
      text-align: center;
      padding: 0 0.5rem;
      margin-bottom: 0;
      color:$text-color;
    }

    &:not(:disabled):focus, &:not(:disabled):hover {
      > img {
        transform: scale(1.15);
      }
    }

    &.copyright {
      span {
        font-size: 25px;
        position: absolute;
        top: 5px;
        right: 8px;
      }
    }
  }
}