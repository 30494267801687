$toggleTransitionDuration: .3s;

.slider-toggle {
    margin-top: 10px;

    > label {
        font-size: 16px;
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        word-break: break-word;

        @media(min-width: $screen-sm-min){
            font-size: 20px;
        }
    }

    &__switch {
        position: relative;
        display: inline-block;
        width: 100%;

        &:not(:first-child) {
            margin-top: 30px;
        }
    }


    &__slider {
        position: relative;
        cursor: pointer;
        background-color: $text-color;
        border-radius: 34px;
        transition: .4s;
        color: white;
        width: 120px;
        height: 35px;
        padding: 7px 12px 7px 35px;
        user-select: none;
        flex-shrink: 0;
        margin: 0 15px;
        overflow: hidden;

        @media(min-width: $screen-sm-min){
            width: 160px;
            height: 50px;
            padding: 12px 12px 12px 68px;
        }

        &:before {
            position: absolute;
            content: "";
            height: 25px;
            width: 25px;
            left: 5px;
            bottom: 5px;
            background-color: white;
            border-radius: 50%;
            box-shadow: 0 0 10px rgba(0,0,0,.3);
            transition: $toggleTransitionDuration cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */

            @media(min-width: $screen-sm-min){
                height: 40px;
                width: 40px;
            }
        }

        @media(min-width: $screen-sm-min){
            &:after {
                content: "";
                position: absolute;
                right: 20px;
                top: 13px;
                width: 10px;
                height: 18px;
                border: solid $color-green;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                opacity: 0;
                transition: opacity 0s;
            }
        }
    }

    &__input {
        display:none;

        &:checked + .slider-toggle__slider {
            background-color: $color-green;
            padding: 7px 37px 7px 12px;

            @media(min-width: $screen-sm-min){
                padding: 12px 46px 12px 40px;
            }

            &:before {
                transform: translateX(84px);
                @media(min-width: $screen-sm-min){
                    transform: translateX(110px);
                }
            }

            @media(min-width: $screen-sm-min){
                &:after {
                    transition: opacity $toggleTransitionDuration $toggleTransitionDuration;
                    opacity: 1;
                }
            }
        }
    }
}
