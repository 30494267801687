.content-box {
  height: 100%;
  background-color: $info-box-bg;
  color: $text-color;
  margin-bottom: 30px;

  &--alt {
    border: 1px solid #e9ebec;
    border-radius: $border-radius-base;
    background-color: $panel-bg;
    box-shadow: 0 1px 2px 0 rgba(23, 25, 26, 0.05);

    .c-content-links {
      display: flex;
      justify-content: center;
    }
  }

  &.content-box--alt img {
    width: auto;

    @media (min-width:$screen-sm) and (max-width:$screen-md) {
      display: inline-block;
    }

  }


  &__image {
    padding: 1rem 1.5rem 0;
    height: 152px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media (min-width:$screen-sm) and (max-width:$screen-md) {
    img {
      display: none;
    }
  }

  &__text {
    padding: 1.5rem;

    h1, h2, h3, h4 {
      font-size: 22px;
      line-height: 1.375;

      &:first-child {
        margin-top: 0;
      }
    }

    a {
      @include themeColor();
    }

    ul, ol {
      font-size: 17px;
      line-height: 24px;
      padding: 0 0 0 1.5rem;
    }

    p,ul,ol,a {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
