.EPiServerForms {
  label:not(.FormChoice--Image__Item) {
    display: block !important;

    + input, + textarea, + select {
      margin-top: 0 !important;
    }

    button {
      display: block !important;
      margin: 10px 0;
    }
  }

  .Form__Element__Caption {
    display: block !important;

    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 3px;
  }

  .FormCaptcha__Input {
    display: block !important;
  }

  .FormChoice {
    margin-top: 35px;

    label {
      font-weight: 500;
    }
  }

  .FormChoice--Image .FormChoice--Image__Item {
    margin: 0 40px 20px 0;
  }

  textarea {
    background: #fcfcfc;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
  }


  select {
    background: #fcfcfc;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    min-height:47px;

    option {
      padding: 10px;
      min-height:47px;
    }

    &[multiple]{
      padding: 0;
    }
  }


  /* Remove controls from Safari and Chrome */
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Removes leftover margin */
  }

  /* Remove controls from Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  input[type=radio], input[type=checkbox]{
    margin: 10px 5px 0 0 !important;
  }

  .FormRange {
    input[type=range]{
      max-width: 95%;
    }
    > span {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      > * {
        flex: 0 1 auto;
      }
    }
  }

  .Form__Status {
    .Form__Status__Message{
      color: $text-color;
      padding: 0;
    }
    .Form__Warning__Message {
      @include themeBaggroundColor();
      color: white;
      margin: 20px 0;
    }
    .Form__Success__Message{
      background-color: white;
    }
  }

  .FormParagraphText {
    margin: 30px 0;
  }

  .FormSubmitButton {
    @extend .btn-submit-spl;

    float:none;
    margin-bottom: 50px;
  }

  .FormResetButton, button[type=submit]:not(.c-button) {
    @extend .btn-submit-spl;
  }
}