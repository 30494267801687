.menu-main {
    background:#4E5961;
    position:relative;

    .theme-yourelectricity & {
        background: $color-blue;
    }

    &__link {
        &.active, &.inpath {
            text-decoration:underline;
        }
    }

    &__current-page {
        &.active {
            text-decoration:underline;
        }
    }

    &__content {
        transition: all .8s 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
        display:none;
        overflow: hidden;
        height: 0;

        &.active, &.inpath {
            display:block;
            height: auto;
        }

        &-wrap {

            &.closed {
                display:none;
            }
        }
    }

    h2 {
        font-size: 18px;
        line-height: 1.6;
        margin:0;
        font-weight: bold;

        &,
        & > a {
            color:white;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        a {
            color: #fff;
            font-size: 18px;
            line-height: 1.6;
            padding: 0 0 10px 7px;
            display: block;

            &:hover {
                color: #fff;
                text-decoration: underline;
            }
        }
    }

    &__flyout {
        background:#4E5961;

        .theme-yourelectricity & {
            background: $color-blue;
        }

        &-navigation {
            padding-bottom: 20px;
            padding-top: 20px;
            border-bottom:1px solid #464F58;
        }

        &-content {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }

    &__arrow-link {
        color: #fff;
        display:block;

        &:active,
        &:hover {
            color:#fff;
        }

        svg {
            height: 22px;
            width: 22px;
            fill: currentColor;
            vertical-align: middle;
            margin-right:5px;
        }
    }
}

.menu-footer {
    background: #eee;
    background-color: #464F58;
    color:white;

    .theme-yourelectricity & {
        background: #01234D;
    }


    .col-sm-4,
    .col-sm-8{
        padding-top: 15px;
        padding-bottom:15px;
    }

    .menu-footer__link-list {

        a {
            color: white;
            font-size: 16px;
            line-height: 1.3;
            float:left;
        }
    }

    h4 {
        font-size: 16px;
        line-height: 1.3;
        margin-bottom: 5px;
    }

    .menu-footer__contact {
        text-align: right;

        .phone {
            font-size: 32px;
            line-height: 1.3;
            display: block;
        }
    }
}
