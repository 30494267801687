﻿.contracts-data {

    &__image {
        @media screen and (min-width: $screen-sm-min) {
            padding-right:0;
        }

        min-height:200px;
        
        img {
            width:100%;
        }

        h4 {
            font-size:25px;
            font-weight:600;
            color:#fff;
            margin:0;

            + p {
                color: white;
                margin-top: 5px;
            }
        }

        & > div {
            position:absolute;
            width:calc(100% - 15px);
            height:100%;
            background:no-repeat left bottom scroll;
            background-size: cover;
            padding:15px;

            @media screen and (min-width: $screen-sm-min) {
                background-size: contain;
            }
        }
    }

    &__themebg {
        background-color:$color-brand;

        .theme-yourelectricity & {
            background-color: $color-turquise;
        }
    }

    &__address {
        @media screen and (min-width: $screen-sm-min) {
            padding-left:0;
        }

        & > div {
            background: #edeeef;
            font-size:16px;
            padding:20px;
        }

        h4 {
            font-size:25px;
            font-weight:600;
            color: $color-brand;
            margin:0;
            margin-bottom:10px;

            .theme-yourelectricity & {
                color: $color-turquise;
            }
        }
    }
}