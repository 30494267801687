﻿.pink-list {
    margin: 56px 0 118px;
    padding: 0 0 0 16px;
    color: $color-brand;
}

.block-link-list {

    a {
        color: #757e85 !important;

        &:visited {
            color: #757e85 !important;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    span {
        vertical-align: middle;
        font-size: 12px;
        font-weight: 600;
        color: $color-brand !important;
    }
}

.pink-list-1 {
    color: $color-brand;
    display: inline-block;
    width: 100%;
}

.no-margin-list {
    margin: 0;
    padding: 0 0 0 16px;
}

.no-style-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.dark-pink-list, .orange-link-list, .pink-link-list {
    color: $color-brand;
    display: block;
    margin: 10px 0;

    &:hover {
        text-decoration: none;
        color: $color-brand;
    }
}


ul.filelist {
    padding: 0;
    list-style: none;

    li {
        float: left;
        padding: 2px 10px 2px 0;

        :before {
            content: "\00BB \0020";
        }
    }
}



/**
    ****************************************************************
    ****************************************************************

    BEM-syntax begins here!

    ****************************************************************
    ****************************************************************
**/


.list {
    padding-left: 25px;

    &--white-arrows {
        li {
            list-style: none;
            position: relative;
            margin-bottom: .3em;

            &:before {
                position: absolute;
                display: block;
                content: "";
                background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzLjkgMTEuNCI+PHBhdGggZD0iTTAuNCwwLjJsMyw2IiBzdHlsZT0ic3Ryb2tlOiNmZmYiLz48cGF0aCBkPSJNMC40LDExLjJsMy02IiBzdHlsZT0ic3Ryb2tlOiNmZmYiLz48L3N2Zz4=') center center no-repeat;
                width: 5px;
                height: 13px;
                left: 0;
                top: 0;
                margin: .2em 0 0 -16px;
            }
        }
    }


    &-item {
        &--normal {
            // Doing a little selector magic to get specificity overrides right.
            &,
            .list & {
                list-style: disc;
                &:before {
                    display: none;
                }
            }
        }
    }
}
