.selfservice-login-wrapper {
	@media (min-width: $screen-tablet) {
		min-width: 50%;
		max-width: none;
		padding-right: 15px;
		float: left;
	}

	> input {
		display: none;

		@for $i from 1 through 2 {
			&:nth-child(#{$i}):checked {
				~ .selfservice-login-tabs {
					> label:nth-child(#{$i}) {
						margin-bottom: 0;
						border-bottom: 1px solid $color-brand;
						background: $color-brand;

						.theme-yourelectricity & {
							background: $color-turquise;
							border-color: $color-turquise;
						}
					}
				}
			}
		}
	}
}

.selfservice-login-tabs {
	display: flex;

	@media (min-width: $screen-tablet) {
		display: block;
		text-align: right;
		font-size: 0;
	}

	> label {
		flex: 1 1 100%;
		margin: 0 0 1px 1px;
		padding: 7px;
		text-align: center;
		color: #fff;
		font-weight: 500;
		font-size: 16px;
		line-height: 1.4;
		background: #90989d;
		cursor: pointer;

		@media (min-width: $screen-tablet) {
			display: inline-block;
			padding: 7px 17px;
		}

		&:first-child {
			margin-left: 0;
		}
	}
}

.selfservice-login-box {
	color: #fff;
	font-weight: normal;
	font-size: 15px;

	@media (min-width: $screen-tablet) {
		display: flex;
		flex-flow: row nowrap;
	}

	* {
		&,
		&:visited,
		&:hover {
			color: inherit;
		}
	}
}

.selfservice-login-box-text,
.selfservice-login-tab-content {
	padding: 20px;
	@include themeBaggroundColor();
}

.selfservice-login-box-text {
	@media (min-width: $screen-tablet) {
		flex: 1 1 315px;
		min-width: 240px;
		max-width: 240px;
	}

	@media (min-width: $screen-desktop) {
		min-width: 315px;
		max-width: 315px;
	}

	h1 {
		margin: 0 0 1em;
		line-height: 1;
		font-size: 2em;

		@media (min-width: $screen-tablet) {
			margin-bottom: 1.8em;
		}
		@media (min-width: $screen-desktop) {
			margin-bottom: 2.4em;
		}
	}

	ul {
		margin: .5em 0;
	}

	h3 {
		font-size: 1.2em;
	}
}

.selfservice-login-tab-content {
	@media (min-width: $screen-tablet) {
		flex: 1 1 auto;
	}

	> div {
		@media (min-width: $screen-tablet) {
			text-align: right;
		}
	}
}