﻿//Top right of content pages and also in footer on main aura pages

.social-icons {
    float: right;
    a {
        background-color: $text-color;
        width: 39px;
        height: 39px;
        border-radius: 20px;
        display: inline-block;
        text-align: center;
        transition: background .5s ease-in-out;
        margin-left: 2px;

        &:hover, &:focus {
            @include themeBaggroundColor();
            text-decoration:none;

            img {
                transform: scale(1.2);
            }
        }

        .theme-yourelectricity & {
            background-color: $color-turquise;

            &:hover, &:focus {
                background-color: $color-darkest-blue;
            }
        }
    }
}