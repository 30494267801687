
.ctabox {
	display: block;
	text-decoration: none;
	color: $text-color;
	font-size: 14px;
	line-height: 1.2;
	margin: 0 0 $grid-gutter-width;
	background: #eee;
	transition: color .1s;
	overflow: hidden;

	@media (min-width: $screen-tablet) {
		height: 270px;
	}

	@media (min-width: $screen-desktop) {
		font-size: 16px;
		height: 300px;
	}

	&__content {
		padding: 20px;
	}

	&__title {
		font-size: 25px;
		@media (min-width: $screen-desktop) {
			font-size: 20px;
		}
		padding: 0 0 5px;
		font-weight: 600;
	}

	&__subtitle {
		padding: 0 0 10px;
		//white-space: nowrap;
	}

	&__description {
		padding: 5px 0 10px;
	}




	&__image {
		content: "";
		height: 150px;
		background: #e4e4e4 50% 50% no-repeat;
		background-size: cover;

		// We'll need some special processing for some of elements if they follow the image. This is mostly about smaller
		// paddings and stuff.
		+ .ctabox__content {
			padding: 15px 20px;

			.ctabox {
				&__description,
				&__subtitle {
					padding: 0;
				}

				&__description {
					@media (min-width: $screen-desktop) {
						//white-space: nowrap;
					}
				}

				&__title {
					color: inherit;
					//white-space: nowrap;
				}
			}
		}
	}

}


a.ctabox {

	&:visited {
		color: $text-color;
	}

	&:hover {
		@include themeColor();
	}

	&:hover,
	&:visited {
		text-decoration: none;
	}
}