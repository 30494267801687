﻿.pre-footer {
    background: #979ea3;
    padding: 30px 0;

    .hidden-sm & ul {
        margin: 0;
    }

    a.title {
        color: #fff;
        //display: block;
        text-align: left;
        font-size: 16px;

        &:hover {
            text-decoration: none;
        }

        .icon {
            width: 44px;
            height: 44px;
            display: block;
            margin: 0 auto 10px;

            &.menu-1 {
                background: url(/Images/menu-sm-1.png) -102px 0 no-repeat;
            }

            &.menu-2 {
                background: url(/Images/menu-sm-2.png) -102px 0 no-repeat;
            }

            &.menu-3 {
                background: url(/Images/menu-sm-3.png) -102px 0 no-repeat;
            }

            &.menu-4 {
                background: url('/Images/menu-sm-4.png') -102px 0 no-repeat;
            }

            &.menu-5 {
                background: url('/Images/menu-sm-5.png') -102px 0 no-repeat;
            }

            &.menu-6 {
                background: url('/Images/menu-sm-6.png') -102px 0 no-repeat;
            }
        }
    }

    &__right {
        ul {
            padding: 0;
            list-style: none;
            li {
                @media(min-width: 768px) {
                    text-align: right;
                }
                a {
                    color:white;
                    text-decoration: none;
                }
            }
        }
    }

    .footer-sub {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            a {
                font-size: 13px;
                color: #fff;

                &:hover {
                    text-decoration: none;
                }

                &.title {
                    font-size:16px;
                }
            }
        }

        .col-20p & > li {
            line-height: 20px;
        }
    }
}

.sub-menu-1 {
    margin-left: 15px !important;
}

.sub-menu-2 {
    margin-left: 22px !important;
}

.sub-menu-3 {
    margin-left: 14px !important;
}

.sub-menu-4 {
    margin-left: 10px !important;
}

.sub-menu-5 {
    margin-left: 32px !important;
}

.sub-menu-6 {
    margin-left: 2px !important;
}

.pre-footer-1 {
    background: #979ea3;
    padding: 30px 0;
    border-top: 1px solid #d4d7d9;
    border-bottom: 1px solid #d4d7d9;
}


@media screen and (min-width:$screen-lg-min) {
    .pre-footer a.title {
        font-size: 20px;
    }

    .sub-menu-2 {
        margin-left: 34px !important;
    }

    .sub-menu-3 {
        margin-left: 26px !important;
    }

    .sub-menu-4 {
        margin-left: 20px !important;
    }

    .sub-menu-5 {
        margin-left: 46px !important;
    }

    .sub-menu-6 {
        margin-left: 14px !important;
    }

    .footer-sub {
        
        li {
            a {

                .pre-footer &.title {
                    font-size:20px;
                }
            }
        }
    }
}


@media (max-width:769px) {
    .pre-footer .footer-sub .title {
        margin-top: 15px;
        font-size: 18px;
    }

    .pre-footer .footer-sub li a {
        font-size: 16px;
    }

    .sub-menu-1,
    .sub-menu-2,
    .sub-menu-3,
    .sub-menu-4,
    .sub-menu-5,
    .sub-menu-6 {
        margin-left: 0 !important;
    }

    .mb30 {
        margin-bottom: 0 !important;
    }

    .footer-sub {
        
        li {
            a {

                .pre-footer &.title {
                    font-size:18px;
                }
            }
        }
    }
}

@media (min-width:768px) {
    .mb30 {
        padding-left: 0;
        margin-bottom: 30px !important;
    }
}

@media screen and (max-width:640px) {
    .pre-footer a.title .icon {
        display: block;
        height: 44px;
        margin: 22px 0;
        width: 44px;
    }

    .pre-footer a.title {
        color: #fff;
        display: block;
        font-size: 18px;
        text-align: left;
    }

    .footer-sub {
        margin-left: 0 !important;

        li {
            a {

                .pre-footer &.title {
                    font-size:18px;
                }
            }
        }
    }
}
