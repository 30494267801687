﻿.navigation-inner {
    border-bottom: 1px solid #d4d7d9;
    padding: 30px 0;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        white-space:nowrap;

        li {
            float:left;
            width: 18.5%;
            display: block;
            margin: 0 .75%;

            a {
                .title {
                    color: #556168;
                    display: inline-block;
                    font-size: 20px;
                    padding: 8px 0 0 10px;
                    text-align: center;
                    vertical-align:middle;

                    .icon-rt-pink {
                        margin-left: 8px;
                    }
                }
                
                &:hover {
                    text-decoration: none;

                    .title {
                        color: #d80b8c;
                    }

                    .large-icon.menu-1,
                    .large-icon.menu-2,
                    .large-icon.menu-3,
                    .large-icon.menu-4,
                    .large-icon.menu-5 {
                        background-position: -52px center;
                    }
                }

                &.active {
                    .title {
                        color: #ef413d;
                    }

                    .large-icon.menu-1,
                    .large-icon.menu-2,
                    .large-icon.menu-3,
                    .large-icon.menu-4,
                    .large-icon.menu-5 {
                        background-position: -52px center;
                    }
                }

                .large-icon {
                    display: inline-block;
                    height: 44px;
                    width: 44px;
                    vertical-align:middle;
                }
            }
        }
    }
}
