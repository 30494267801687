.news-list {
  .news-item {
    font-size: 17px;
    line-height: 1.4;

    > a {
      text-decoration: underline;
      @include themeColor();

      &:visited {
        @include themeDarkenColor();
      }
    }

    a:visited {
      @include themeDarkenColor();
    }
  }
}