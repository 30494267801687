.grid--flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  clear:both;
  margin: 0 -15px;

  > div {
    flex: 0 1 auto;
    margin: 8px 15px;
    @media (min-width: $screen-sm-min) {
      margin: 15px;
      width: calc( 50% - 30px );
    }
    @media (min-width: $screen-md-min) {
      width: calc( 33.333% - 30px );
    }
    @media (min-width: $screen-lg-min) {
      width: calc( 25% - 30px );
    }
  }
}