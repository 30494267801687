﻿

.btn-primary-spl {
    @include themeBaggroundColor();
    border: 1px solid #fff;
    color: #fff;
    display: inline-block;
    font-size: 30px;
    padding: 10px 20px;
    margin-top: 30px;

    &:hover {
        color: #fff;
        text-decoration: none;

        .btn-primary-spl__icon {
            span svg {
                transform: translateX(0);
            }
        }
    }
    
    &:visited {
        color: #fff;
    }


    &[disabled]{
        background-color: darkgrey;
    }

    &--slim {
        font-size: 20px;
    }

    &.loading {
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 5px;
            opacity: .8;
            background-image: linear-gradient(-45deg, #fff 25%, rgba(#fff, 0), 25%, rgba(#fff, 0) 50%, #fff 50%, #fff 75%, rgba(#fff, 0) 75%);
            background-size: 50px 50px;
            box-shadow: inset 0 10px 0 rgba(255, 255, 255, .5);
            animation: loading-bar 1s linear infinite;
        }
    }
}

.btn-submit-spl {
  @include button-size(1rem, 2rem, 16px, 1, 3px);

  min-width: 130px;
  font-weight: bold;
  border: none;
  color: #fff;
  background-color: $color-blue;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 150ms ease-in-out,
  color 150ms ease-in-out;

  &:hover {
    color: #fff;
    background-color: $color-dark-blue;
  }

  .theme-yourelectricity & {
    background-size: 200% auto;
    background-color: transparent;
    background-image: linear-gradient(120deg, $color-turquise 0%, #12827E, $color-turquise 100%);
    transition: background-position 150ms * 2 ease-in-out;

    &:hover {
      color: #fff;
      background-position: right center;
    }
  }
}

.btn-white {
    background: url(/Images/arrow-pvt-lg.png) scroll right no-repeat #fff;
    border: 1px solid $color-brand;
    color: $text-color;
    display: inline-block;
    font-size: 30px;
    padding: 10px 90px 10px 20px;
    margin-top: 30px;
    float: left;

    &:hover {
        color: #fff;
        text-decoration: none;
        background: url(/Images/arrow-pvt-lg-wt.png) scroll right no-repeat $color-brand;
    }
    
    &:visited {
        color: #fff;
    }
}

.btn__block {
    display: flex;
    background-color: white;
    color: $text-color;
    text-transform: none;
    margin-top: 20px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    text-decoration: none !important;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-md-min) {
        padding: 16px;
    }

    > span {
        flex: 0 1 auto;

        &:first-child {
            flex-grow: 1;
            text-align: center;
        }

        &:last-child {
            width: 35px;
            height: 28px;
            margin-left: 20px;
        }
    }

    svg {
        fill: currentColor;
        transition: transform .3s ease-out;
    }

    &:hover, &:active {
        color: $text-color;
        svg {
            transform: translateX(10px);
        }
    }

    &:visited {
        color: $text-color;
    }

    &--theme {
        @include themeBaggroundColor();
        color: white;

        &:hover, &:active {
            color: white;
        }

        &:visited {
            color: white;
        }
    }
}

.btn--arrow {
    color:white;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-transform: uppercase;

    svg {
        margin-left: 20px;
        fill: currentColor;
        transition: transform .3s ease-out;
    }

    &:hover, &:active {
        @include themeColor();

        svg {
            transform: translateX(10px);
        }
    }

    &:visited {
        color:white;
    }
}

.btn-search-spl {
    position: relative;
    color: $color-brand;

    .theme-yourelectricity & {
        color: $color-turquise !important;
    }

    background-color: transparent;
    display: inline-block;
    float: left;
    font-size: 26px;
    margin-top: 10px;
    padding: 6px 90px 6px 20px;
    border: none;

    svg {
        position: absolute;
        right: 0;
        top: 6px;
        width:47px;
        height: 37px;
        fill: currentColor;
        transition: transform .3s ease-out;
    }

    @media (max-width:769px) {
        margin: 15px -25px;
        background: 0 0;
        padding: 0;
        svg {
            display: none;
        }
    }

    &:hover,&:focus {
        svg {
            transform: translateX(10px);
        }
    }
}

.btn-wind-spl {
    background: url('/Images/arrow-wind-right-white.png') scroll 90% center no-repeat $color-brand;
    border: 1px solid $color-brand;
    color: #fff;
    display: inline-block;
    font-size: 30px;
    padding: 11px 60px 10px 20px;
    margin-top: 30px;
    float: left;
    background-position: 90% center;

    &:hover {
        color: $color-brand;
        text-decoration: none;
        background: url(/Images/arrow-wind-right-pvt.png) scroll 90% center no-repeat #fff;
    }
    
    &:visited {
        color: $color-brand;
    }
}

.btn-wind-inverted-spl {
    background: url('/Images/arrow-wind-right-pvt.png') scroll 90% center no-repeat #fff;
    border: 1px solid $color-brand;
    color: $color-brand;
    display: inline-block;
    font-size: 30px;
    padding: 11px 60px 10px 20px;
    margin-top: 30px;
    float: left;
    background-position: 90% center;

    &:hover {
        color: #fff;
        text-decoration: none;
        background: url(/Images/arrow-wind-right-white.png) scroll 90% center no-repeat $color-brand;
    }
    
    &:visited {
        color: #fff;
    }
}


.back-btn {
    display: inline-block;
    color: $text-color;
    padding: 20px 0;

    &:hover {
        color: $text-color;
        text-decoration: none;
    }
    
    &:visited {
        color: $text-color;
    }

    img {
        margin-right: 10px;
    }

    &--icon {
        display: flex;
        align-items: center;

        .arrow-link--left {
            height: 20px;
            width: 25px;
            display: inline-block;
            margin-right: 10px;
            transition: transform .5s cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &:hover {
            .arrow-link--left {
                transform: translateX(-5px);
            }
        }
    }
}

.btn-brandcolor {
    @include themeBaggroundColor();

    color:white;
    height: 60px;
    width: 100%; //container takes care of width
    display: flex;
    justify-content: center;
    align-items: center;
    border:none;
    transition: background .3s ease-out;
    text-decoration: none;
    font-size: 20px;
    cursor: pointer;

    &:hover, &:focus {
        @include themeDarkenBaggroundColor();
        color:white;
        transition-duration: .15s;
    }

    &.disabled{
        background:#aaa;

        &:hover {
            background:#aaa;
        }
    }
}

.btn-link {
    background: none;
    border: none;
    padding: 0;
    color: #000;
}

.btn-link:hover {
    text-decoration: underline;
    color: #000;
}

.btn--triangle-shadow {
    @include btn--triangle-shadow(58px, 58, 100px);
    min-height:60px;
    a,
    button {
        flex: 0 1 auto;
        min-height: 60px;
        margin-top: 0;
        transition: none;

        &:hover {
            background: $link-color;
        }
    }
}


/* NEW buttons */