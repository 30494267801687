
.svg-icon {

	&--current-fill {
		fill: currentColor;
        stroke: transparent;
	}

	&--current-stroke {
		stroke: currentColor;
        fill: transparent;
	}

}