#flex-map {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width:100%;
  max-width: 100%;
  overflow: hidden;
  .active-map-area {
    position:relative;
    width:334px;
    height:295px;

    &:before {
      content: url(/static/dist/img/map/th.png);
      position: absolute;
      right: 100%;
      top: -84px;
    }
    &:after {
      content: url(/static/dist/img/map/tv.png);
      position: absolute;
      left: 100%;
      top: -84px;
    }
  }
}