﻿.red-txt {
    color: $color-error;
}

.white-txt {
    color: #fff;
    font-size: 36px;
    line-height: 39px;
}

.email-txt {
    color: #fff;
    font-size: 20px;
    margin-top: 64px;

    a {
        color: #fff;
        font-weight: 600;
        border-bottom: 1px solid #fff;

        &:active, &:focus, &:hover {
            color: #fff;
            text-decoration: none;
            outline: 0;
        }
    }
}

.text-sp-center {
    margin: 80px 0;
    text-align: center;
}

.pink-txt {
    @include themeColor();
}

.pink-txt-reg {
    @include themeColor();
    margin-bottom: 24px;
}

.store-glass-txt {
    bottom: 50px;
    color: $color-brand;
    display: block;
    font-size: 24px;
    left: 92px;
    letter-spacing: 0;
    position: absolute;
    text-align: center;
}

.dark-text {
    color: #76034a;
}

.grey-txt {

    &,
    &:hover {
        color: $text-color;
    }
}

.phone-number {
    color: $color-brand;
    font-size: 24px;
    vertical-align: bottom;
}

.small-txt {
    font-size: 12px;
}

.insta-image {
    text-align: center;

    img {
        border: 9px solid #fff;
        box-shadow: 2px 2px 5px -2px #000;
        margin: 10px 0;
        max-height: 220px;
    }
}

.white-font {

    &,
    &:hover {
        color: #fff;
    }

    &.special {
        display: block;
        float: left;
        font-size: 26px;
        margin-bottom: 10px;
        margin-top: 34px;
    }
}

.white-font-36 {
    font-size: 36px;
    color: #fff;
}

.red-font-36 {
    font-size: 36px;
    color: #eb403c;
    margin-top: 40px;
}

.red-font {

    &,
    &:hover {
        color: #ef413d;
    }
}

.blk-bld {
    color: $text-color;
    text-decoration: underline;
    font-weight: 600;

    &:hover {
        color: $text-color;
    }
}

.wt-bld {
    color: #fff;
    text-decoration: underline;
    font-weight: 600;

    &:hover {
        color: #fff;
    }
}

span.field-validation-error {
    color: $color-error;
}

.error-message {
    background: 40px 10px no-repeat $color-brand;
    @include themeBaggroundColor();
    color: #fff;
    display: none;
    margin: 30px 0 0 80px;
    min-height: 70px;
    padding: 10px 40px 10px 80px;
    vertical-align: top;
    width: 360px !important;
    float: left;

    &.field-validation-error {
        display:block;
        color: #fff;
    }
}

span.error-message {
    padding: 20px 40px 10px;
    vertical-align: top;
    width: 100% !important;
    float: none;
    text-align: center;
    margin: 40px 0;
}

